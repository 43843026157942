import React from 'react';
import PublicationSelectList from '../PublicationSelectList/PublicationSelectList';
import AuthorSelectList from '../AuthorSelectList/AuthorSelectList';
import YearSelectList from '../YearSelectList/YearSelectList';
import SubjectSelectList from '../SubjectSelectList/SubjectSelectList';
//import { useHistory } from "react-router-dom"; 

function ArticleSearchForm(props) {
    /**
    let history = useHistory();
    
    const [publicationIds, setPublicationIds] = useState([]);
    const [authorIds, setAuthorIds] = useState([]);
    const [yearIds, setYearIds] = useState([]);
    const [subjectIds, setSubjectIds] = useState([]);

    const getValues = (e) => {
      var options = e.target.options;
      var value = [];
      for (var i = 0, l = options.length; i < l; i++) {
        if (options[i].selected) {
          value.push(options[i].value);
        }
      }
    }

    const HandlePublicationChange = (e) => {
      setPublicationIds(getValues(e))
    }

    const HandleAuthorChange = (e) => {
      setAuthorIds(getValues(e))
    }

    const HandleYearChange = (e) => {
      setYearIds(getValues(e))
    }

    const HandleSubjectChange = (e) => {
      setSubjectIds(getValues(e))
    }
    */

    const HandleSubmit = (e) => {
      /**
      e.preventDefault();
      console.log("HandleSubmit .. ")
      
      console.log("Redirecting to .. ArticleSearch");
      history.push({
        pathname: '/ArticleSearch',
        search: '?publicationIds[]=1,2,3',
        state: {
          publicationIds: publicationIds,
          authorIds: authorIds,
          yearIds: yearIds,
          subjectIds: subjectIds
        }  
      })
       */
    }
  
    return (
      <form onSubmit={HandleSubmit} action="/articlesearch">
        <div className="row">
          <div className="col-sm-12">
            <h3 className="text-center">Article Search</h3>
          </div>
        </div>
        
        <div className="row">
            <div className="col-sm-3">
                <div className="card box-shadow">
                  <div className="card-header">
                    <h4 className="my-0 font-weight-normal text-center">Publication</h4>
                  </div>
                  <div className="card-body">
                    <div className="form-group">
                      <PublicationSelectList selectedIds={[]} updateIds={value => {console.log("updateIds: " + value)}}/>
                    </div>
                </div>
              </div>
            </div>
            
            <div className="col-sm-3">
                <div className="card box-shadow">
                  <div className="card-header">
                    <h4 className="my-0 font-weight-normal text-center">Author</h4>
                  </div>
                  <div className="card-body">
                    <div className="form-group">
                      <AuthorSelectList selectedIds={[]} updateIds={value => {console.log("updateIds: " + value)}}/>
                    </div>
                </div>
              </div>
            </div>

            <div className="col-sm-3">
                <div className="card box-shadow">
                  <div className="card-header">
                    <h4 className="my-0 font-weight-normal text-center">Year</h4>
                  </div>
                  <div className="card-body">
                    <div className="form-group">
                    <YearSelectList selectedIds={[]} updateIds={value => {console.log("updateIds: " + value)}}/>
                    </div>
                </div>
              </div>
            </div>

            <div className="col-sm-3">
                <div className="card box-shadow">
                  <div className="card-header">
                    <h4 className="my-0 font-weight-normal text-center">Subject</h4>
                  </div>
                  <div className="card-body">
                    <div className="form-group">
                      <SubjectSelectList selectedIds={[]} updateIds={value => {console.log("updateIds: " + value)}}/>
                    </div>
                </div>
              </div>
            </div>
        </div>

        <div className="row">
          <div className="col-sm-12">
            <div className="row top-buffer">
              <div className="col text-center">
                <button type="submit" className="btn btn-secondary">Search</button>
              </div>
            </div>
          </div>
        </div>
      </form>
    );
  }
  export default ArticleSearchForm;