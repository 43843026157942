import React from 'react';

import Select from 'react-select';
import makeAnimated from 'react-select/animated';

const animatedComponents = makeAnimated();

export default function AuthorSelectList(props) {
  const options = [
    {"value":122,"label":"A. A. C. Swinton","url":"a_a_c_swinton"},
    {"value":176,"label":"A. Bernard Behrend","url":"a_bernard_behrend"},
    {"value":190,"label":"A. Bottone","url":"a_bottone"},
    {"value":173,"label":"A. Frederick Collins","url":"a_frederick_collins"},
    {"value":23,"label":"A. Gaillard","url":"a_gaillard"},
    {"value":82,"label":"A. Gray","url":"a_gray"},
    {"value":129,"label":"A. Slaby","url":"a_slaby"},
    {"value":121,"label":"A. Vosmaer","url":"a_vosmaer"},
    {"value":102,"label":"A.F. McKissick","url":"a_f_mckissick"},
    {"value":104,"label":"A.T.","url":"a_t"},
    {"value":41,"label":"A.W.R./G.","url":"a_w_r_g"},
    {"value":46,"label":"Albert Schmvalue","url":"albert_schmvalue"},
    {"value":18,"label":"Albion T. Snell","url":"albion_t_snell"},
    {"value":134,"label":"Alfred C. Prentice","url":"alfred_c_prentice"},
    {"value":132,"label":"Alfred G. Dell","url":"alfred_g_dell"},
    {"value":207,"label":"Allan L. Benson","url":"allan_l_benson"},
    {"value":205,"label":"Allen S. Dana","url":"allen_s_dana"},
    {"value":246,"label":"Arnold D. Prince","url":"arnold_d_prince"},
    {"value":198,"label":"Arthur B. Reeve","url":"arthur_b_reeve"},
    {"value":65,"label":"Arthur Brisbane","url":"arthur_brisbane"},
    {"value":111,"label":"Arthur E. Scanes","url":"arthur_e_scanes"},
    {"value":226,"label":"Arthur Gorden Webster","url":"arthur_gorden_webster"},
    {"value":165,"label":"Arthur Wikle","url":"arthur_wikle"},
    {"value":236,"label":"Arthur Wynne","url":"arthur_wynne"},
    {"value":241,"label":"B. A. Behrend","url":"b_a_behrend"},
    {"value":232,"label":"B. G. Lamme","url":"b_g_lamme"},
    {"value":184,"label":"B.A. Behrend","url":"b_a_behrend"},
    {"value":110,"label":"B.P. Remy","url":"b_p_remy"},
    {"value":251,"label":"Benj. F. Painter","url":"benj_f_painter"},
    {"value":242,"label":"Benjamin Franklin Miessner","url":"benjamin_franklin_miessner"},
    {"value":147,"label":"C.D. Wood","url":"c_d_wood"},
    {"value":42,"label":"C.E.L. Brown","url":"c_e_l_brown"},
    {"value":59,"label":"C.F. Scott","url":"c_f_scott"},
    {"value":15,"label":"C.J. Kintner","url":"c_j_kintner"},
    {"value":57,"label":"C.J.H. Woodbury","url":"c_j_h_woodbury"},
    {"value":240,"label":"Calvert Townley","url":"calvert_townley"},
    {"value":178,"label":"Camille Flammarion","url":"camille_flammarion"},
    {"value":35,"label":"Carl Hering","url":"carl_hering"},
    {"value":163,"label":"Carl Synder","url":"carl_synder"},
    {"value":171,"label":"Charles Appeleton Terry","url":"charles_appeleton_terry"},
    {"value":117,"label":"Charles Barnard","url":"charles_barnard"},
    {"value":131,"label":"Charles Culver Johnson","url":"charles_culver_johnson"},
    {"value":253,"label":"Charles F. Scott","url":null},
    {"value":235,"label":"Charles Manierre","url":"charles_manierre"},
    {"value":172,"label":"Chas F. Scott","url":"chas_f_scott"},
    {"value":29,"label":"Chas Steinmetz","url":"chas_steinmetz"},
    {"value":148,"label":"Chauncy Montgomery McGovern","url":"chauncy_montgomery_mcgovern"},
    {"value":69,"label":"Curtis Brown","url":"curtis_brown"},
    {"value":109,"label":"D.F. St. Clair","url":"d_f_st_clair"},
    {"value":87,"label":"Dayton C. Miller","url":"dayton_c_miller"},
    {"value":215,"label":"Derby Wireless Club","url":"derby_wireless_club"},
    {"value":149,"label":"Desire Stanton","url":"desire_stanton"},
    {"value":6,"label":"Dr. Louis Duncan","url":"dr_louis_duncan"},
    {"value":245,"label":"E. Easterling","url":"e_easterling"},
    {"value":166,"label":"E. Guarini","url":"e_guarini"},
    {"value":48,"label":"E. Hospitalier","url":"e_hospitalier"},
    {"value":68,"label":"E. Jay Edwards","url":"e_jay_edwards"},
    {"value":211,"label":"E. Leslie Gilliams","url":"e_leslie_gilliams"},
    {"value":70,"label":"E. M. F.","url":"e_m_f"},
    {"value":47,"label":"E. Raverot","url":"e_raverot"},
    {"value":199,"label":"E. Taylor Jones","url":"e_taylor_jones"},
    {"value":186,"label":"E.C. Slipper","url":"e_c_slipper"},
    {"value":202,"label":"E.F. Stearns","url":"e_f_stearns"},
    {"value":203,"label":"E.J. Edwards","url":"e_j_edwards"},
    {"value":84,"label":"E.J. Wessels","url":"e_j_wessels"},
    {"value":128,"label":"Edgar Saltus","url":"edgar_saltus"},
    {"value":228,"label":"Edward H. Smith","url":"edward_h_smith"},
    {"value":99,"label":"Edward J. Wessels","url":"edward_j_wessels"},
    {"value":223,"label":"Edward Marshall","url":"edward_marshall"},
    {"value":90,"label":"Edward P. Thompson","url":"edward_p_thompson"},
    {"value":187,"label":"Edward Sylvester Morse","url":"edward_sylvester_morse"},
    {"value":8,"label":"Edwin F. Northrup/Eliott Woods","url":"edwin_f_northrup_eliott_woods"},
    {"value":86,"label":"Edwin J Houston/A.E. Kennelly","url":"edwin_j_houston_a_e_kennelly"},
    {"value":94,"label":"Edwin J. Houston/A. E. Kennelly","url":"edwin_j_houston_a_e_kennelly"},
    {"value":168,"label":"Electrometer","url":"electrometer"},
    {"value":28,"label":"Elihu Thompson","url":"elihu_thompson"},
    {"value":4,"label":"Elihu Thomson","url":"elihu_thomson"},
    {"value":162,"label":"Eliot Lord","url":"eliot_lord"},
    {"value":96,"label":"Elliott Woods","url":"elliott_woods"},
    {"value":108,"label":"Elmer G. Willyoung/H. Lyman Sayen","url":"elmer_g_willyoung_h_lyman_sayen"},
    {"value":107,"label":"Elmer Willyoung","url":"elmer_willyoung"},
    {"value":12,"label":"Elmer Willyoung/H. Lyman Sayen","url":"elmer_willyoung_h_lyman_sayen"},
    {"value":239,"label":"Emerson Easterling","url":"emerson_easterling"},
    {"value":88,"label":"ENGINEERING;","url":"engineering"},
    {"value":73,"label":"Ernest K. Adams","url":"ernest_k_adams"},
    {"value":61,"label":"F. Arnold","url":"f_arnold"},
    {"value":2,"label":"F. Edwin Northrup","url":"f_edwin_northrup"},
    {"value":74,"label":"F. Himstedt","url":"f_himstedt"},
    {"value":19,"label":"F. Jarvis Patten","url":"f_jarvis_patten"},
    {"value":81,"label":"F. Jarvis Pattern","url":"f_jarvis_pattern"},
    {"value":105,"label":"F.C. Allsop","url":"f_c_allsop"},
    {"value":53,"label":"F.E.P.","url":"f_e_p"},
    {"value":85,"label":"F.J.R.","url":"f_j_r"},
    {"value":137,"label":"F.L. Christman","url":"f_l_christman"},
    {"value":112,"label":"F.S. Kolle","url":"f_s_kolle"},
    {"value":92,"label":"Francis Leon Christman","url":"francis_leon_christman"},
    {"value":219,"label":"Francois Magri","url":"francois_magri"},
    {"value":174,"label":"Frank G. Carpenter","url":"frank_g_carpenter"},
    {"value":170,"label":"Frank H. Taylor","url":"frank_h_taylor"},
    {"value":238,"label":"Frank P. Stockbrvaluege","url":"frank_p_stockbrvaluege"},
    {"value":201,"label":"Frank Parker Stockbrvaluege","url":"frank_parker_stockbrvaluege"},
    {"value":119,"label":"Franklin Chester","url":"franklin_chester"},
    {"value":51,"label":"Franklin L. Pope","url":"franklin_l_pope"},
    {"value":120,"label":"Franz Bendt","url":"franz_bendt"},
    {"value":231,"label":"Frederick Finch Strong","url":"frederick_finch_strong"},
    {"value":101,"label":"Frederick J. Smith","url":"frederick_j_smith"},
    {"value":14,"label":"G. Cutter","url":"g_cutter"},
    {"value":10,"label":"G. Marconi","url":"g_marconi"},
    {"value":116,"label":"G.B. Qalsron","url":"g_b_qalsron"},
    {"value":52,"label":"G.E. Bonney","url":"g_e_bonney"},
    {"value":216,"label":"G.W. Hale","url":"g_w_hale"},
    {"value":185,"label":"Garret P. Serviss","url":"garret_p_serviss"},
    {"value":114,"label":"Garrett P. Serviss","url":"garrett_p_serviss"},
    {"value":179,"label":"George Ethelbert Walsh","url":"george_ethelbert_walsh"},
    {"value":83,"label":"George Forbes","url":"george_forbes"},
    {"value":77,"label":"George Heli Guy","url":"george_heli_guy"},
    {"value":222,"label":"George Henry Payne","url":"george_henry_payne"},
    {"value":194,"label":"George T. Hanchett","url":"george_t_hanchett"},
    {"value":21,"label":"George Westinghouse","url":"george_westinghouse"},
    {"value":7,"label":"Guglielmo Marconi","url":"guglielmo_marconi"},
    {"value":225,"label":"H. Gernsback","url":"h_gernsback"},
    {"value":62,"label":"H. M. Martin/W. H. Palmer","url":"h_m_martin_w_h_palmer"},
    {"value":39,"label":"H. Newman Lawrence","url":"h_newman_lawrence"},
    {"value":218,"label":"H. Winfield Secor","url":"h_winfield_secor"},
    {"value":123,"label":"H.C. Pocklington","url":"h_c_pocklington"},
    {"value":192,"label":"H.L. Transtrom","url":"h_l_transtrom"},
    {"value":66,"label":"H.M. Martin/W.H. Palmer Jr.","url":"h_m_martin_w_h_palmer_jr"},
    {"value":135,"label":"H.S. Norrie","url":"h_s_norrie"},
    {"value":138,"label":"H.W. Phillips","url":"h_w_phillips"},
    {"value":25,"label":"Harold Binney","url":"harold_binney"},
    {"value":22,"label":"Harris J. Ryan","url":"harris_j_ryan"},
    {"value":206,"label":"Henry Jevons","url":"henry_jevons"},
    {"value":125,"label":"Henry Onequi","url":"henry_onequi"},
    {"value":95,"label":"Henry Tyrrell","url":"henry_tyrrell"},
    {"value":126,"label":"Henry Weissman","url":"henry_weissman"},
    {"value":229,"label":"Hugo Gernsback","url":"hugo_gernsback"},
    {"value":31,"label":"INDUSTRIES;","url":"industries"},
    {"value":182,"label":"J. Brown","url":"j_brown"},
    {"value":183,"label":"J. Hanson Boyden","url":"j_hanson_boyden"},
    {"value":237,"label":"J. Harris Rogers","url":"j_harris_rogers"},
    {"value":189,"label":"J. Holmes Wilson","url":"j_holmes_wilson"},
    {"value":175,"label":"J. Pike","url":"j_pike"},
    {"value":43,"label":"J. Sinclair Fairfax","url":"j_sinclair_fairfax"},
    {"value":17,"label":"J. Swinburne","url":"j_swinburne"},
    {"value":155,"label":"J. Wright","url":"j_wright"},
    {"value":197,"label":"J.A. Fleming/G.B. Dyke","url":"j_a_fleming_g_b_dyke"},
    {"value":45,"label":"J.E.H. Gordon","url":"j_e_h_gordon"},
    {"value":34,"label":"J.J. Thomson","url":"j_j_thomson"},
    {"value":115,"label":"J.N.P.","url":"j_n_p"},
    {"value":113,"label":"J.P.N.","url":"j_p_n"},
    {"value":233,"label":"J.W. Beckman","url":"j_w_beckman"},
    {"value":234,"label":"Jacques Mortane","url":"jacques_mortane"},
    {"value":204,"label":"James H. Doran","url":"james_h_doran"},
    {"value":67,"label":"John Foord","url":"john_foord"},
    {"value":133,"label":"John T. Pitkin","url":"john_t_pitkin"},
    {"value":144,"label":"John Throwbrvaluege","url":"john_throwbrvaluege"},
    {"value":140,"label":"John Trowbrvaluege","url":"john_trowbrvaluege"},
    {"value":124,"label":"John W. Trowbrvaluege","url":"john_w_trowbrvaluege"},
    {"value":250,"label":"Joseph H. Kraus","url":"joseph_h_kraus"},
    {"value":160,"label":"Joseph S. Ames","url":"joseph_s_ames"},
    {"value":32,"label":"Joseph Wetzler","url":"joseph_wetzler"},
    {"value":60,"label":"Julian A. Moses","url":"julian_a_moses"},
    {"value":151,"label":"Julian Hawthorne","url":"julian_hawthorne"},
    {"value":118,"label":"Julius Chambers","url":"julius_chambers"},
    {"value":161,"label":"Kauffman/Hall","url":"kauffman_hall"},
    {"value":158,"label":"Knoblauch","url":"knoblauch"},
    {"value":54,"label":"L. Demengel","url":"l_demengel"},
    {"value":36,"label":"L. Gutman","url":"l_gutman"},
    {"value":100,"label":"L. Miller","url":"l_miller"},
    {"value":44,"label":"L. Pyke","url":"l_pyke"},
    {"value":214,"label":"L.S. Uphoff","url":"l_s_uphoff"},
    {"value":164,"label":"Laurance Hawkins","url":"laurance_hawkins"},
    {"value":79,"label":"Lewis Buckley Stillwell","url":"lewis_buckley_stillwell"},
    {"value":106,"label":"Louis Bell","url":"louis_bell"},
    {"value":13,"label":"Louis Duncan","url":"louis_duncan"},
    {"value":27,"label":"Louis Duncon/W.F.C. Hasson","url":"louis_duncon_w_f_c_hasson"},
    {"value":37,"label":"Ludwig Gutman","url":"ludwig_gutman"},
    {"value":9,"label":"Ludwig Gutmann","url":"ludwig_gutmann"},
    {"value":38,"label":"M.I. Pupin","url":"m_i_pupin"},
    {"value":224,"label":"M.U.","url":"m_u"},
    {"value":180,"label":"M.W. Harrington","url":"m_w_harrington"},
    {"value":58,"label":"Madison Buell","url":"madison_buell"},
    {"value":146,"label":"Marcel Huart","url":"marcel_huart"},
    {"value":200,"label":"Marcel Roland","url":"marcel_roland"},
    {"value":188,"label":"Marconi","url":"marconi"},
    {"value":97,"label":"Mator","url":"mator"},
    {"value":139,"label":"MINING & SCIENTIFIC PRESS;","url":"mining_scientific_press"},
    {"value":209,"label":"Moore Stuart","url":"moore_stuart"},
    {"value":227,"label":"Morris W. Jones","url":"morris_w_jones"},
    {"value":71,"label":"Moses P. Handy","url":"moses_p_handy"},
    {"value":80,"label":"Mrs. Bloomfield Moore","url":"mrs_bloomfield_moore"},
    {"value":143,"label":"N. Tesla","url":"n_tesla"},
    {"value":145,"label":"N.G. Worth","url":"n_g_worth"},
    {"value":11,"label":"Nelson W. Perry","url":"nelson_w_perry"},
    {"value":30,"label":"Nicola Tesla","url":"nicola_tesla"},
    {"value":3,"label":"Nikola Tesla","url":"nikola_tesla"},
    {"value":243,"label":"Nikola Tesla/M. Frederick Kerby","url":"nikola_tesla_m_frederick_kerby"},
    {"value":252,"label":"No Author","url":"no_author"},
    {"value":103,"label":"Orrin E. Dunlap","url":"orrin_e_dunlap"},
    {"value":167,"label":"P.L. Narasu","url":"p_l_narasu"},
    {"value":154,"label":"Physicist","url":"physicist"},
    {"value":191,"label":"R. A. Fessenden","url":"r_a_fessenden"},
    {"value":157,"label":"R.A. Fessenden","url":"r_a_fessenden"},
    {"value":33,"label":"Rankin Kennedy","url":"rankin_kennedy"},
    {"value":208,"label":"Richard Maxwell Winans","url":"richard_maxwell_winans"},
    {"value":210,"label":"Robert P. Knox","url":"robert_p_knox"},
    {"value":64,"label":"Robert Underwood Johnson","url":"robert_underwood_johnson"},
    {"value":230,"label":"Rogers D. Rusk","url":"rogers_d_rusk"},
    {"value":244,"label":"Ruth Raphael","url":"ruth_raphael"},
    {"value":50,"label":"S. Bottone","url":"s_bottone"},
    {"value":220,"label":"S. Gernsback/H. Winfield Secor","url":"s_gernsback_h_winfield_secor"},
    {"value":150,"label":"S.E. Solly, M.D.","url":"s_e_solly_m_d"},
    {"value":141,"label":"S.R.M.","url":"s_r_m"},
    {"value":221,"label":"Samuel Cohen","url":"samuel_cohen"},
    {"value":193,"label":"Samuel E. Hitt","url":"samuel_e_hitt"},
    {"value":49,"label":"Sigma","url":"sigma"},
    {"value":1,"label":"Silvanus P. Thompson","url":"silvanus_p_thompson"},
    {"value":136,"label":"T. J. M.","url":"t_j_m"},
    {"value":156,"label":"T.C.M.","url":"t_c_m"},
    {"value":152,"label":"T.H. Muras","url":"t_h_muras"},
    {"value":142,"label":"T.J.M.","url":"t_j_m"},
    {"value":63,"label":"Thomas C. Martin","url":"thomas_c_martin"},
    {"value":78,"label":"Thomas Commerford Martin","url":"thomas_commerford_martin"},
    {"value":20,"label":"Thomas Edison","url":"thomas_edison"},
    {"value":247,"label":"Thomas W. Benson","url":"thomas_w_benson"},
    {"value":249,"label":"Thomas Waller","url":"thomas_waller"},
    {"value":72,"label":"Translation-Nikola Tesla","url":"translation_nikola_tesla"},
    {"value":56,"label":"V.","url":"v"},
    {"value":55,"label":"V. Bjerknes","url":"v_bjerknes"},
    {"value":181,"label":"Vans Brown","url":"vans_brown"},
    {"value":89,"label":"W. C. Peake","url":"w_c_peake"},
    {"value":169,"label":"W. Hack","url":"w_hack"},
    {"value":213,"label":"W.D. Duddell","url":"w_d_duddell"},
    {"value":130,"label":"W.J. Clarke","url":"w_j_clarke"},
    {"value":98,"label":"W.J. Herdmen","url":"w_j_herdmen"},
    {"value":195,"label":"W.J. Shaw","url":"w_j_shaw"},
    {"value":24,"label":"W.M. FairFax","url":"w_m_fairfax"},
    {"value":196,"label":"W.S. Couch","url":"w_s_couch"},
    {"value":212,"label":"Waldemar Kaempffert","url":"waldemar_kaempffert"},
    {"value":76,"label":"Walter Stephenson","url":"walter_stephenson"},
    {"value":75,"label":"Walter T. Stephenson","url":"walter_t_stephenson"},
    {"value":16,"label":"Wayde Vander","url":"wayde_vander"},
    {"value":26,"label":"William A. Anthony","url":"william_a_anthony"},
    {"value":5,"label":"William Bissing","url":"william_bissing"},
    {"value":40,"label":"William Crookes","url":"william_crookes"},
    {"value":153,"label":"William F. Durand","url":"william_f_durand"},
    {"value":217,"label":"William I. Wyman","url":"william_i_wyman"},
    {"value":127,"label":"William Rollins","url":"william_rollins"},
    {"value":177,"label":"William Stanley","url":"william_stanley"},
    {"value":248,"label":"Winfield Secor","url":"winfield_secor"},
    {"value":93,"label":"Wm. A. Anthony","url":"wm_a_anthony"},
    {"value":91,"label":"WM. Stuy","url":"wm_stuy"},
    {"value":159,"label":"X.","url":"x"}
  ];

  function checkOptionValue(option) {
    if(props.selectedIds != null) {
      return props.selectedIds.includes(option.value.toString());
    } else {
      return false;
    }
  }

  function updateSelectedIds(values) {
    var selectedIds = [];
    if(values !== null) {
      selectedIds = values.map(value => value.value);
    }

    console.log("selectIds: " + selectedIds);
    props.updateIds(selectedIds)
  }

  function getFacetValues() {
    var facetIds = (props.facets !== null && props.facets !== undefined) ? props.facets : [];

    if(facetIds.length > 0) {
      var result = options.filter(option => {
        return facetIds.includes(option.value)
      });
  
      return result;
    } else {
      return undefined;
    }
  }

  return (
    <Select
      name="authorIds[]"
      closeMenuOnSelect={false}
      components={animatedComponents}
      isMulti
      isDisabled={props.isDisabled}
      defaultValue={options.filter(checkOptionValue)}
      value={getFacetValues()}
      onChange={updateSelectedIds}
      options={options}
    />
  );
}