import React from 'react';

import Select from 'react-select';
import makeAnimated from 'react-select/animated';

const animatedComponents = makeAnimated();

export default function YearSelectList(props) {
  const options = [
    {"value":1886,"label":"1886","url":"1886"},
    {"value":1887,"label":"1887","url":"1887"},
    {"value":1888,"label":"1888","url":"1888"},
    {"value":1889,"label":"1889","url":"1889"},
    {"value":1890,"label":"1890","url":"1890"},
    {"value":1891,"label":"1891","url":"1891"},
    {"value":1892,"label":"1892","url":"1892"},
    {"value":1893,"label":"1893","url":"1893"},
    {"value":1894,"label":"1894","url":"1894"},
    {"value":1895,"label":"1895","url":"1895"},
    {"value":1896,"label":"1896","url":"1896"},
    {"value":1897,"label":"1897","url":"1897"},
    {"value":1898,"label":"1898","url":"1898"},
    {"value":1899,"label":"1899","url":"1899"},
    {"value":1900,"label":"1900","url":"1900"},
    {"value":1901,"label":"1901","url":"1901"},
    {"value":1902,"label":"1902","url":"1902"},
    {"value":1903,"label":"1903","url":"1903"},
    {"value":1904,"label":"1904","url":"1904"},
    {"value":1905,"label":"1905","url":"1905"},
    {"value":1906,"label":"1906","url":"1906"},
    {"value":1907,"label":"1907","url":"1907"},
    {"value":1908,"label":"1908","url":"1908"},
    {"value":1909,"label":"1909","url":"1909"},
    {"value":1910,"label":"1910","url":"1910"},
    {"value":1911,"label":"1911","url":"1911"},
    {"value":1912,"label":"1912","url":"1912"},
    {"value":1913,"label":"1913","url":"1913"},
    {"value":1914,"label":"1914","url":"1914"},
    {"value":1915,"label":"1915","url":"1915"},
    {"value":1916,"label":"1916","url":"1916"},
    {"value":1917,"label":"1917","url":"1917"},
    {"value":1918,"label":"1918","url":"1918"},
    {"value":1919,"label":"1919","url":"1919"},
    {"value":1920,"label":"1920","url":"1920"},
    {"value":1921,"label":"1921","url":"1921"},
    {"value":1922,"label":"1922","url":"1922"},
    {"value":1923,"label":"1923","url":"1923"},
    {"value":1924,"label":"1924","url":"1924"},
    {"value":1925,"label":"1925","url":"1925"},
    {"value":1926,"label":"1926","url":"1926"},
    {"value":1927,"label":"1927","url":"1927"},
    {"value":1928,"label":"1928","url":"1928"},
    {"value":1929,"label":"1929","url":"1929"},
    {"value":1930,"label":"1930","url":"1930"}
  ];

  function checkOptionValue(option) {
    if(props.selectedIds != null) {
      return props.selectedIds.includes(option.value.toString());
    } else {
      return false;
    }
  }

  function updateSelectedIds(values) {
    var selectedIds = [];
    if(values !== null) {
      selectedIds = values.map(value => value.value);
    }

    console.log("selectIds: " + selectedIds);
    props.updateIds(selectedIds)
  }

  function getFacetValues() {
    var facetIds = (props.facets !== null && props.facets !== undefined) ? props.facets : [];

    if(facetIds.length > 0) {
      var result = options.filter(option => {
        return facetIds.includes(option.value)
      });
  
      return result;
    } else {
      return undefined;
    }
  }

  return (
    <Select
      name="yearIds[]"
      closeMenuOnSelect={false}
      components={animatedComponents}
      isMulti
      isDisabled={props.isDisabled}
      defaultValue={options.filter(checkOptionValue)}
      value={getFacetValues()}
      onChange={updateSelectedIds}
      options={options}
    />
  );
}