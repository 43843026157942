import React from 'react';

import { ArticleSearchForm } from '../../components/ArticleSearchForm';

const Home = props => {
  return (
    <div className="container">
      <div className="row">
        <div className="col-sm-3">
          <p><img src="/tesla.jpg" className="teslaphoto" alt="Nikola Tesla" /></p>
        </div>
        <div className="col-sm-4 text-justify">
          <p>The "Tesla Collection" is the most comprehensive compilation of newspaper and periodical material ever assembled by or about Nikola Tesla. The Collection begins on August 14, 1886 and continues through December 11, 1920. Comprising approximately 1,700 separate items totaling more than 4,200 pages, the Collection is drawn from both American and British publications and is reproduced directly from the original English Language material.</p>
        </div>
        <div className="col-sm-4 text-justify">
          <p>Seen together "The Tesla Collection" not only sheds new light on the early days of electricity, and the development and widespread acceptance by the public and scientific community of Alternating Current, but also provides a one-of-a-kind look into the early days of X-Ray, Wireless, Remote Control, Robotics and the efforts and experiments made by Tesla into the development and delivery of wireless Electricity.</p>
        </div>
      </div>

      <ArticleSearchForm />

    </div>
  )
 }
 export default Home;