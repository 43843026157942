import React from 'react';
import axios from 'axios';

import './Article.css';

class Article extends  React.Component {
	constructor(props) {
		super(props);
		// :year/:publication/:author/:article
		let params = this.props.match.params
		let url = params.year + "/" + params.publication + "/" + params.author + "/" + params.article
		console.log("url: " + url)
		this.state = {
			url: url,
            article: {
				id: 0,
				volume: 0,
				page: 0,
				title: "",
				fullText: "",
				publication: {
					id: 0,
					name: "",
					url: "",
					status: "active",
					created: "2013-01-02T15:55:47.000+00:00",
					modified: "2013-08-14T22:20:37.000+00:00"
				},
				author: {
					id: 0,
					name: "",
					url: "",
					status: "active",
					created: "2013-01-02T15:55:47.000+00:00",
					modified: "2013-08-14T22:20:34.000+00:00"
				},
				pages: [
				{
					id: 0,
					filename: "",
					fullPath: "",
					text: null,
					status: "active",
					created: "2013-01-02T15:56:03.000+00:00",
					modified: "2013-01-02T15:56:03.000+00:00"
				}]
			},
            loading: false,
            message: ''
		};
	}
	componentDidMount() {
		this.fetchSearchResults(this.state.url);
	}
	fetchSearchResults(url) {
		const searchUrl = `/api/v1/search/url?url=${url}`;
		if (this.cancel) {
			// Cancel the previous request before making a new request
			this.cancel.cancel();
		}
		// Create a new CancelToken
		this.cancel = axios.CancelToken.source();
		axios
			.get(searchUrl, {
				cancelToken: this.cancel.token,
			})
			.then((res) => {
				console.log(res);
				const resultNotFoundMsg = res.data == null
					? 'There are no more search results. Please try a new search.'
					: '';
				this.setState({
					article: res.data,
					message: resultNotFoundMsg,
					loading: false,
				});
			})
			.catch((error) => {
				if (axios.isCancel(error) || error) {
					this.setState({
						loading: false,
						message: 'Failed to fetch results.  Please check network',
					});
				}
			});
	}
	render() {
	  console.log(this.state.article);
	  return (
		<div itemScope itemType="http://schema.org/NewsArticle" className="container">
			<div className="row">
				<div className="col-md-5">
					Name:
				</div>
				<div className="col-md-5">
					<strong><span itemProp="name">{this.state.article.title}</span></strong>
				</div>
			</div>
			<div className="row">
				<div className="col-md-5">
					Publication:
				</div>
				<div className="col-md-5">
					<strong><span itemProp="name">{this.state.article.publication.name}</span></strong>
				</div>
			</div>
			<div className="row">
				<div className="col-md-5">
					Date:
				</div>
				<div className="col-md-5">
					<strong><span itemProp="dateCreated"><time dateTime={this.state.article.date}>{this.state.article.date}</time></span></strong>
				</div>
			</div>
			<div className="row">
				<div className="col-md-5">
					Author:
				</div>
				<div className="col-md-5">
					<strong><span itemProp="author">{this.state.article.author != null ? this.state.article.author.name : ''}</span></strong>
				</div>
			</div>

			{this.state.article.pages.map(function(page) {
				if(page.fullPath !== "") {
					return (
						<div key={page.id}>
							<img src={page.fullPath} alt={page.filename} className="article-page-image" />
						</div>
					);
				} else {
					return (<div></div>);
				}
			})}
		</div>
		);
	}
};
export default Article;