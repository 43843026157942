import React from 'react';
import { Home } from './views/Home';
import Images from './views/Images/Images';
import { Timeline } from './views/Timeline';
import { Directors } from './views/Directors';
import { About } from './views/About';
import { Contact } from './views/Contact';

import { NavBar } from './components/NavBar';
import { NavFooter } from './components/NavFooter';

import { SearchResultsContainer } from './components/SearchResultsContainer';
import { Article } from './components/Article';

import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";

export const Routes = () => {
  return (
    <Router>
      <NavBar />
      <Switch>
        <Route exact path="/" component={Home} />
        <Route path="/images" component={Images} />
        <Route path="/timeline" component={Timeline} />
        <Route path="/directors" component={Directors} />
        <Route path="/about" component={About} />
        <Route path="/contact" component={Contact} />
        <Route path="/articlesearch" component={SearchResultsContainer} />
        <Route path="/tesla_articles/:year/:publication/:author/:article" component={Article} />
      </Switch>
      <NavFooter />
    </Router>
  );
};