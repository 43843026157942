import React from 'react';

import Select from 'react-select';
import makeAnimated from 'react-select/animated';

const animatedComponents = makeAnimated();

export default function PublicationSelectList(props) {
  const options = [
    {"value":69,"label":"AMERICAN ELECTRICIAN","url":"american_electrician"},
    {"value":18,"label":"AMERICAN INST. ELECT. ENGINEERS - TRANSACTIONS","url":"american_inst_elect_engineers_transactions"},
    {"value":22,"label":"AMERICAN MANUFACTURER","url":"american_manufacturer"},
    {"value":86,"label":"AMERICAN X-RAY JOURNAL","url":"american_x_ray_journal"},
    {"value":62,"label":"April 3, 1896 [429]","url":"april_3_1896_429"},
    {"value":57,"label":"BLACKWOOD'S EDINBURGH MAGAZINE","url":"blackwood_s_edinburgh_magazine"},
    {"value":148,"label":"BOSTON POST","url":"boston_post"},
    {"value":78,"label":"BROOKLYN CITIZEN","url":"brooklyn_citizen"},
    {"value":125,"label":"BROOKLYN DAILY TIMES","url":"brooklyn_daily_times"},
    {"value":71,"label":"BROOKLYN EAGLE","url":"brooklyn_eagle"},
    {"value":87,"label":"BUFFALO EVENING NEWS","url":"buffalo_evening_news"},
    {"value":40,"label":"BUFFALO NEWS/SUNDAY EXPRESS","url":"buffalo_news_sunday_express"},
    {"value":80,"label":"CAMBRvalueGE PHILOSOPHICAL SOCIETY - PROCEEDINGS","url":"cambrvaluege_philosophical_society_proceedings"},
    {"value":37,"label":"CASSIERS MAGAZINE","url":"cassiers_magazine"},
    {"value":43,"label":"CENTURY MAGAZINE","url":"century_magazine"},
    {"value":76,"label":"CHAUTAUQUAN","url":"chautauquan"},
    {"value":72,"label":"CHICAGO TIMES-HERALD","url":"chicago_times_herald"},
    {"value":99,"label":"CHICAGO TRIBUNE","url":"chicago_tribune"},
    {"value":83,"label":"COLLIERS","url":"colliers"},
    {"value":73,"label":"COLORADO CITY IRIS","url":"colorado_city_iris"},
    {"value":100,"label":"COLORADO SPRINGS EVENING TELEGRAPH","url":"colorado_springs_evening_telegraph"},
    {"value":102,"label":"COLORADO SPRINGS GAZETTE","url":"colorado_springs_gazette"},
    {"value":123,"label":"COLORADO SPRINGS TELEGRAPH","url":"colorado_springs_telegraph"},
    {"value":52,"label":"COMMERCE & COMMERCIAL BULLETIN","url":"commerce_commercial_bulletin"},
    {"value":56,"label":"COMMERCIAL & FINANCIAL CHRONICLE","url":"commercial_financial_chronicle"},
    {"value":146,"label":"COSMOPOLITAN","url":"cosmopolitan"},
    {"value":149,"label":"CRAFTSMAN","url":"craftsman"},
    {"value":92,"label":"CRITERION","url":"criterion"},
    {"value":94,"label":"CURRENT LITERATURE","url":"current_literature"},
    {"value":155,"label":"CURRENT OPINION","url":"current_opinion"},
    {"value":101,"label":"DENVER ROCKY MOUNTAIN NEWS","url":"denver_rocky_mountain_news"},
    {"value":59,"label":"DETROIT FREE PRESS","url":"detroit_free_press"},
    {"value":126,"label":"DETROIT NEWS TRIBUNE","url":"detroit_news_tribune"},
    {"value":116,"label":"ELECTRIC JOURNAL","url":"electric_journal"},
    {"value":19,"label":"ELECTRIC POWER","url":"electric_power"},
    {"value":96,"label":"ELECTRICAL AGE","url":"electrical_age"},
    {"value":1,"label":"ELECTRICAL ENGINEER (Lon)","url":"electrical_engineer_lon"},
    {"value":9,"label":"ELECTRICAL ENGINEER (NY)","url":"electrical_engineer_ny"},
    {"value":3,"label":"ELECTRICAL EXPERIMENTER","url":"electrical_experimenter"},
    {"value":39,"label":"ELECTRICAL INDUSTRIES","url":"electrical_industries"},
    {"value":36,"label":"ELECTRICAL REVIEW","url":"electrical_review"},
    {"value":129,"label":"ELECTRICAL REVIEW & WESTERN ELECTRICIAN","url":"electrical_review_western_electrician"},
    {"value":4,"label":"ELECTRICAL REVIEW (Lon)","url":"electrical_review_lon"},
    {"value":7,"label":"ELECTRICAL REVIEW (NY)","url":"electrical_review_ny"},
    {"value":2,"label":"ELECTRICAL WORLD","url":"electrical_world"},
    {"value":105,"label":"ELECTRICAL WORLD & ENGINEER","url":"electrical_world_engineer"},
    {"value":145,"label":"ELECTRICIAN & MECHANIC","url":"electrician_mechanic"},
    {"value":8,"label":"ELECTRICITY","url":"electricity"},
    {"value":15,"label":"ENGINEERING","url":"engineering"},
    {"value":121,"label":"ENGINEERING NEWS RECORD","url":"engineering_news_record"},
    {"value":21,"label":"ENGLISH MECHANIC & WORLD OF SCIENCE","url":"english_mechanic_world_of_science"},
    {"value":153,"label":"EXPORT AMERICAN INDUSTRIES","url":"export_american_industries"},
    {"value":103,"label":"FACTS","url":"facts"},
    {"value":28,"label":"FORTHIGHTLY REVIEW","url":"forthightly_review"},
    {"value":110,"label":"HARPERS BAZAR","url":"harpers_bazar"},
    {"value":25,"label":"HARPERS WEEKLY","url":"harpers_weekly"},
    {"value":124,"label":"HARVARD ILLUSTRATED MAGAZINE","url":"harvard_illustrated_magazine"},
    {"value":58,"label":"HE ENGINEERING MAGAZINE","url":"he_engineering_magazine"},
    {"value":75,"label":"ILLUSTRATED AMERICAN","url":"illustrated_american"},
    {"value":13,"label":"INDUSTRIES","url":"industries"},
    {"value":95,"label":"JOURNAL OF ELECTRICITY POWER & GAS","url":"journal_of_electricity_power_gas"},
    {"value":70,"label":"JOURNAL OF ELECTRICITY, POWER & GAS","url":"journal_of_electricity_power_gas"},
    {"value":81,"label":"JOURNAL OF FRANKLYN INSTITUTE","url":"journal_of_franklyn_institute"},
    {"value":12,"label":"JOURNAL OF THE FRANKLIN INSTITUTE","url":"journal_of_the_franklin_institute"},
    {"value":24,"label":"June 5, 1891 [546]","url":"june_5_1891_546"},
    {"value":111,"label":"JUNIOR MUNSEY","url":"junior_munsey"},
    {"value":61,"label":"LESLIE'S WEEKLY","url":"leslie_s_weekly"},
    {"value":32,"label":"LIGHTNING","url":"lightning"},
    {"value":26,"label":"LITERARY DIGEST","url":"literary_digest"},
    {"value":147,"label":"LOS ANGELES EXAMINER","url":"los_angeles_examiner"},
    {"value":64,"label":"LOUISVILLE COURIER JOURNAL","url":"louisville_courier_journal"},
    {"value":136,"label":"MACHINERY","url":"machinery"},
    {"value":120,"label":"MANUFACTURERS RECORD","url":"manufacturers_record"},
    {"value":107,"label":"MARINE ENGINEERING","url":"marine_engineering"},
    {"value":48,"label":"McCLURES","url":"mcclures"},
    {"value":47,"label":"McCLURES MAGAZINE","url":"mcclures_magazine"},
    {"value":90,"label":"MECHANICAL ENGINEER","url":"mechanical_engineer"},
    {"value":89,"label":"MEDICAL RECORD","url":"medical_record"},
    {"value":10,"label":"MINING & SCIENTIFIC PRESS","url":"mining_scientific_press"},
    {"value":142,"label":"MINNEAPOLIS MORNING TRIBUNE","url":"minneapolis_morning_tribune"},
    {"value":115,"label":"MODEL ENGINEER & ELECTRICIAN","url":"model_engineer_electrician"},
    {"value":143,"label":"MODERN ELECTRICS","url":"modern_electrics"},
    {"value":134,"label":"MOTOR WORLD","url":"motor_world"},
    {"value":104,"label":"MOUNTAIN SUNSHINE","url":"mountain_sunshine"},
    {"value":151,"label":"NATION","url":"nation"},
    {"value":38,"label":"NATIONAL ELECTRIC LIGHT ASSOCIATION BULLETIN","url":"national_electric_light_association_bulletin"},
    {"value":14,"label":"NATURE","url":"nature"},
    {"value":45,"label":"NEW SCIENCE REVIEW","url":"new_science_review"},
    {"value":118,"label":"NEW YORK AMERICAN","url":"new_york_american"},
    {"value":154,"label":"NEW YORK EVENING JOURNAL","url":"new_york_evening_journal"},
    {"value":114,"label":"NEW YORK EVENING MAIL & EXPRESS","url":"new_york_evening_mail_express"},
    {"value":65,"label":"NEW YORK EVENING SUN","url":"new_york_evening_sun"},
    {"value":144,"label":"NEW YORK EVENING TELEGRAM","url":"new_york_evening_telegram"},
    {"value":41,"label":"NEW YORK HERALD","url":"new_york_herald"},
    {"value":77,"label":"NEW YORK JOURNAL & ADVERTISER","url":"new_york_journal_advertiser"},
    {"value":117,"label":"NEW YORK JOURNAL & AMERICAN","url":"new_york_journal_american"},
    {"value":66,"label":"NEW YORK MORNING JOURNAL","url":"new_york_morning_journal"},
    {"value":74,"label":"NEW YORK POST","url":"new_york_post"},
    {"value":130,"label":"NEW YORK PRESS","url":"new_york_press"},
    {"value":63,"label":"NEW YORK RECORD","url":"new_york_record"},
    {"value":33,"label":"NEW YORK SUN","url":"new_york_sun"},
    {"value":157,"label":"NEW YORK TELEGRAM","url":"new_york_telegram"},
    {"value":23,"label":"NEW YORK TIMES","url":"new_york_times"},
    {"value":44,"label":"NEW YORK TRIBUNE","url":"new_york_tribune"},
    {"value":46,"label":"NEW YORK WORLD","url":"new_york_world"},
    {"value":31,"label":"NINETEENTH CENTURY","url":"nineteenth_century"},
    {"value":20,"label":"NORTH AMERICAN REVIEW","url":"north_american_review"},
    {"value":93,"label":"November 19, 1898 [506]","url":"november_19_1898_506"},
    {"value":51,"label":"OUTLOOK","url":"outlook"},
    {"value":98,"label":"PEARSON'S MAGAZINE","url":"pearson_s_magazine"},
    {"value":113,"label":"PHILADELPHIA NORTH AMERICAN","url":"philadelphia_north_american"},
    {"value":84,"label":"PHILADELPHIA PRESS","url":"philadelphia_press"},
    {"value":133,"label":"PHILOSOPHICAL MAGAZINE","url":"philosophical_magazine"},
    {"value":132,"label":"PHILOSOPHICAL SOCIETY LONDON - PROCEEDINGS","url":"philosophical_society_london_proceedings"},
    {"value":42,"label":"PHYSICAL REVIEW","url":"physical_review"},
    {"value":67,"label":"PITTSBURGH DISPATCH","url":"pittsburgh_dispatch"},
    {"value":128,"label":"POPULAR ELECTRICITY","url":"popular_electricity"},
    {"value":139,"label":"POPULAR ELECTRICITY MAGAZINE","url":"popular_electricity_magazine"},
    {"value":138,"label":"POPULAR MECHANICS","url":"popular_mechanics"},
    {"value":108,"label":"POPULAR SCIENCE","url":"popular_science"},
    {"value":68,"label":"PROGRESS OF THE WORLD","url":"progress_of_the_world"},
    {"value":34,"label":"PUBLIC OPINION","url":"public_opinion"},
    {"value":159,"label":"QST","url":"qst"},
    {"value":122,"label":"READER","url":"reader"},
    {"value":156,"label":"RECONSTRUCTION","url":"reconstruction"},
    {"value":16,"label":"REVIEW OF REVIEWS","url":"review_of_reviews"},
    {"value":112,"label":"SATURDAY REVIEW","url":"saturday_review"},
    {"value":49,"label":"SAVANNAH MORNING NEWS","url":"savannah_morning_news"},
    {"value":55,"label":"SCIENCE","url":"science"},
    {"value":158,"label":"SCIENCE & INVENTION","url":"science_invention"},
    {"value":30,"label":"SCIENTIFIC AMERICAN","url":"scientific_american"},
    {"value":17,"label":"SCIENTIFIC AMERICAN SUPPLEMENT","url":"scientific_american_supplement"},
    {"value":79,"label":"SCRIBNER'S MAGAZINE","url":"scribner_s_magazine"},
    {"value":131,"label":"SEATTLE POST","url":"seattle_post"},
    {"value":150,"label":"ST LOUIS POST DISPATCH","url":"st_louis_post_dispatch"},
    {"value":152,"label":"ST. LOUIS POST DISPATCH","url":"st_louis_post_dispatch"},
    {"value":82,"label":"ST. LOUIS POST-DISPATCH","url":"st_louis_post_dispatch"},
    {"value":85,"label":"ST. LOUIS REPUBLIC","url":"st_louis_republic"},
    {"value":27,"label":"STREET RAILWAY JOURNAL","url":"street_railway_journal"},
    {"value":91,"label":"SUCCESS","url":"success"},
    {"value":127,"label":"TECHNICAL WORLD MAGAZINE","url":"technical_world_magazine"},
    {"value":137,"label":"THE AUTOMOBILE","url":"the_automobile"},
    {"value":53,"label":"THE CRITIC","url":"the_critic"},
    {"value":88,"label":"THE ELECTRICAL AGE","url":"the_electrical_age"},
    {"value":5,"label":"THE ELECTRICAL WORLD","url":"the_electrical_world"},
    {"value":6,"label":"THE ELECTRICIAN","url":"the_electrician"},
    {"value":54,"label":"THE ENGINEER","url":"the_engineer"},
    {"value":106,"label":"THE ENGINEERING & BUILDING RECORD","url":"the_engineering_building_record"},
    {"value":35,"label":"THE ENGINEERING MAGAZINE","url":"the_engineering_magazine"},
    {"value":60,"label":"THE INDEPENDENT","url":"the_independent"},
    {"value":29,"label":"THE SPECTATOR","url":"the_spectator"},
    {"value":119,"label":"THE STATE","url":"the_state"},
    {"value":141,"label":"THE WORLD'S WORK","url":"the_world_s_work"},
    {"value":97,"label":"TOWN TROPICS","url":"town_tropics"},
    {"value":135,"label":"WASHINGTON POST","url":"washington_post"},
    {"value":109,"label":"WESTERN  ELECTRICIAN","url":"western_electrician"},
    {"value":11,"label":"WESTERN ELECTRICIAN","url":"western_electrician"},
    {"value":140,"label":"WORLD TODAY","url":"world_today"},
    {"value":50,"label":"YALE SCIENTIFIC MONTHLY","url":"yale_scientific_monthly"}
  ];

  function checkOptionValue(option) {
    if(props.selectedIds != null) {
      return props.selectedIds.includes(option.value.toString());
    } else {
      return false;
    }
  }

  function updateSelectedIds(values) {
    var selectedIds = [];
    if(values !== null) {
      selectedIds = values.map(value => value.value);
    }

    console.log("selectIds: " + selectedIds);
    props.updateIds(selectedIds)
  }

  function getFacetValues() {
    var facetIds = (props.facets !== null && props.facets !== undefined) ? props.facets : [];

    if(facetIds.length > 0) {
      var result = options.filter(option => {
        return facetIds.includes(option.value)
      });
  
      return result;
    } else {
      return undefined;
    }
  }

  return (
    <Select
      name="publicationIds[]"
      closeMenuOnSelect={false}
      components={animatedComponents}
      isMulti
      isDisabled={props.isDisabled}
      defaultValue={options.filter(checkOptionValue)}
      value={getFacetValues()}
      onChange={updateSelectedIds}
      options={options}
    />
  );
}