import React from 'react';

import './Timeline.css';
import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';

import 'react-vertical-timeline-component/style.min.css';

const Timeline = props => {
  return (
    <div className="container" style={{ backgroundColor: 'rgba(0,0,0,.03)' }}>
      <VerticalTimeline>
  <VerticalTimelineElement
      className="vertical-timeline-element--work"
      date="1856"
      iconStyle={{ background: '#b9c5c7', color: '#fff' }}
    >
      <h3 className="vertical-timeline-element-title">Nikola Tesla was born</h3>
      <img src="/timeline/nikola_tesla_1879.jpeg" alt="Milutin Tesla" className="timelineImage" /><br />  
      <p>
      Nikola Tesla was born on July 10, 1856 in Smiljan, Lika, which was then part of the Austo-Hungarian Empire, region of Croatia. His father, Milutin Tesla was a Serbian Orthodox Priest and his mother Djuka Mandic was an inventor in her own right of household appliances. 
      </p>
  </VerticalTimelineElement>
  <VerticalTimelineElement
    className="vertical-timeline-element--work"
    date="1881"
    iconStyle={{ background: '#b9c5c7', color: '#fff' }}
  >
    <h3 className="vertical-timeline-element-title">Budapest</h3>
    <img src="/timeline/induction_motor.jpeg" alt="Induction Motor" className="timelineImage" /><br /> 
    <p>
      Tesla began his career as an electrical engineer with a telephone company in Budapest in 1881. It was there, as Tesla was walking with a friend through the city park that the elusive solution to the rotating magnetic field flashed through his mind. With a stick, he drew a diagram in the sand explaining to his friend the principle of the induction motor. 
    </p>
    <a href="http://teslacollection.com/tesla_articles/1917/electrical_experimenter/samuel_cohen/dr_nikola_tesla_and_his_achievements" target="_blank" rel="noreferrer"><img src="https://teslacollection.s3.amazonaws.com/thumbnails/Volume%2021/Volume%2021%20Page%20096.jpg" alt="Dr. Nikola Tesla And His Achievements" className="timelineImage" /><p className="timelineText">Above: Dr. Nikola Tesla And His Achievements</p></a>
  </VerticalTimelineElement>
  <VerticalTimelineElement
    className="vertical-timeline-element--work"
    date="1882"
    iconStyle={{ background: '#b9c5c7', color: '#fff' }}
  >
    <h3 className="vertical-timeline-element-title">Rotating Magnetic Field</h3>
    <p>
    In February 1882, Tesla discovered the rotating magnetic field, a fundamental principle in physics and the basis of nearly all devices that use alternating current.  Tesla brilliantly adapted the principle of rotating magnetic field for the construction of alternating current induction motor and the polyphase system for the generation, transmission, distribution and use of electrical power.
    </p>
    <a href="http://teslacollection.com/tesla_articles/1890/electrical_engineer_ny/elihu_thompson/tesla_s_new_alternating_motors" target="_blank" rel="noreferrer"><img src="https://teslacollection.s3.amazonaws.com/thumbnails/Volume%2002/Volume%2002%20Page%20077.jpg" alt="Tesla's New Alternating Motors" className="timelineImage" /><p className="timelineText">Above: Tesla's New Alternating Motors</p></a>
  </VerticalTimelineElement>
  <VerticalTimelineElement
    className="vertical-timeline-element--work"
    date="1884"
    iconStyle={{ background: '#b9c5c7', color: '#fff' }}
  >
    <h3 className="vertical-timeline-element-title" >Coming to America</h3>
    <img src="/timeline/nikola_tesla_1885.png" alt="Nikola Tesla" className="timelineImage" /><br />  
    <p>
    
    Young Nikola Tesla came to the United States in 1884 with an introduction letter from Charles Batchelor to Thomas Edison: "I know two great men," wrote Batchelor, "one is you and the other is this young man."
    </p>
  </VerticalTimelineElement>
  <VerticalTimelineElement
    className="vertical-timeline-element--work"
    date="1891"
    iconStyle={{ background: '#b9c5c7', color: '#fff' }}
  >
    <h3 className="vertical-timeline-element-title">Tesla Coil</h3>
    <p>
    The Tesla coil, which he invented in 1891, is widely used today in radio and television sets and other electronic equipment.  That year also marked the date of Tesla's United States citizenship. 
    </p>
    <a href="http://teslacollection.com/tesla_articles/1916/electrical_experimenter/samuel_cohen/lightning_made_to_order" target="_blank" rel="noreferrer"><img src="https://teslacollection.s3.amazonaws.com/thumbnails/Volume%2021/Volume%2021%20Page%20086.jpg" alt="Lightning Made To Order" className="timelineImage" /><p className="timelineText">Above: Lightning Made To Order</p></a>
  </VerticalTimelineElement>
  <VerticalTimelineElement
    className="vertical-timeline-element--work"
    date="1893"
    iconStyle={{ background: '#b9c5c7', color: '#fff' }}
  >
    <h3 className="vertical-timeline-element-title">Columbian Exposition</h3>
    <p>
    Tesla astonished the world by demonstrating. the wonders of alternating current electricity at the World Columbian Exposition in Chicago in 1893. Alternating current became standard power in the 20th Century.  This accomplishment changed the world.
    The Century Magazine published Tesla's principles of telegraphy without wires, popularizing scientific lectures given before Franklin Institute in February 1893. 
    </p>
    <a href="http://teslacollection.com/tesla_articles/1893/electrical_world/franklin_l_pope/the_westinghouse_electric_and_manufacturing_co_at_world_s_fair" target="_blank" rel="noreferrer"><img src="https://teslacollection.s3.amazonaws.com/thumbnails/Volume%2008/Volume%2008%20Page%20114.jpg" alt="The Westinghouse Electric And Manufacturing Co. At World's Fair" className="timelineImage" /><p className="timelineText">Above: The Westinghouse Electric And Manufacturing Co. At World's Fair</p></a>
  </VerticalTimelineElement>
  <VerticalTimelineElement
    className="vertical-timeline-element--work"
    date="1895"
    iconStyle={{ background: '#b9c5c7', color: '#fff' }}
  >
    <h3 className="vertical-timeline-element-title">Niagara Falls Powerplant</h3>
    <p>
    He designed the first hydroelectric powerplant in Niagara Falls in 1895, which was the final victory of alternating current.  The achievement was covered widely in the world press, and Tesla was praised as a hero world wide.  King Nikola of Montenegro conferred upon him the Order of Danilo.
    </p>
    <a href="http://teslacollection.com/tesla_articles/1893/buffalo_news_sunday_express/madison_buell/what_electricity_will_do_for_us" target="_blank" rel="noreferrer"><img src="https://teslacollection.s3.amazonaws.com/thumbnails/Volume%2006/Volume%2006%20Page%20083.jpg" alt="What Electricity Will Do For Us" className="timelineImage" /><p className="timelineText">Above: What Electricity Will Do For Us</p></a>
  </VerticalTimelineElement>
  <VerticalTimelineElement
    className="vertical-timeline-element--work"
    date="1896"
    iconStyle={{ background: '#b9c5c7', color: '#fff' }}
  >
    <h3 className="vertical-timeline-element-title">X-Ray</h3>
    <p>
    The Electrical Review in 1896 published X-rays of a man, made by Tesla, with X-ray tubes of his own design.  They appeared at the same time as when Roentgen announced his discovery of X-rays.  Tesla never attempted to proclaim priority.  Roentgen congratulated Tesla on his sophisticated X-ray pictures, and  Tesla even wrote Roentgen's name on one of his films.  He experimented with shadowgraphs similar to those that later were to be used by Wilhelm Rontgen when he discovered X-rays in 1895.  Tesla's countless experiments included work on a carbon button lamp, on the power of electrical resonance, and on various types of lightning.  Tesla invented the special vacuum tube which emitted light to be used in photography.
    </p>
    <a href="http://teslacollection.com/tesla_articles/1896/electrical_review_ny/nikola_tesla/tesla_s_startling_results_in_radiography_at_great_distances_through_considerable_thickness_and_substance" target="_blank" rel="noreferrer"><img src="https://teslacollection.s3.amazonaws.com/thumbnails/Volume%2010/Volume%2010%20%20Page%20151.jpg" alt="Tesla's Startling Results In Radiography At Great Distances Through Considerable Thickness And Substance" className="timelineImage" /><p className="timelineText">Above: Tesla's Startling Results In Radiography At Great Distances Through Considerable Thickness And Substance</p></a>
  </VerticalTimelineElement>
  <VerticalTimelineElement
    className="vertical-timeline-element--work"
    date="1898"
    iconStyle={{ background: '#b9c5c7', color: '#fff' }}
  >
    <h3 className="vertical-timeline-element-title">Remote Control</h3>
    <p>
    At Madison Square Garden, Tesla demonstrated a radio-controlled boat which he hoped to sell as a guided torpedo to navies around the world.
    </p>
    <a href="http://teslacollection.com/tesla_articles/1898/new_york_journal_advertiser/nikola_tesla/a_submarine_destroyer_that_really_destroys_my_new_submarine_destroyer" target="_blank" rel="noreferrer"><img src="https://teslacollection.s3.amazonaws.com/thumbnails/Volume%2013/Volume%2013%20Page%20151.jpg" alt="A Submarine Destroyer That Really Destroys: A Without a Soul On Board to Guide It" className="timelineImage" /><p className="timelineText">Above: A Submarine Destroyer That Really Destroys: A Without a Soul On Board to Guide It</p></a>
  </VerticalTimelineElement>
  <VerticalTimelineElement
    className="vertical-timeline-element--work"
    date="1899 - 1900"
    iconStyle={{ background: '#b9c5c7', color: '#fff' }}
  >
    <h3 className="vertical-timeline-element-title">Colorado Springs</h3>
    <p>
    Tesla built an experimental station in Colorado Springs, Colorado in 1899, to experiment with high voltage, high frequency electricity and other phenomena.
    In Colorado Springs, where he stayed from May 1899 until 1900, Tesla made what he regarded as his most important discovery-- terrestrial stationary waves.  By this discovery he proved that the Earth could be used as a conductor and would be as responsive as a tuning fork to electrical vibrations of a certain frequency.  He also lighted 200 lamps without wires from a distance of 25 miles( 40 kilometers) and created man-made lightning.  At one time he was certain he had received signals from another planet in his Colorado laboratory, a claim that was met with disbelief in some scientific journals.
    </p>
    <a href="http://teslacollection.com/tesla_articles/1901/colliers/nikola_tesla/talking_with_the_planets" target="_blank" rel="noreferrer"><img src="https://teslacollection.s3.amazonaws.com/thumbnails/Volume%2015/Volume%2015%20Page%20157.jpg" alt="Talking With The Planets" className="timelineImage" /><p className="timelineText">Above: Talking With The Planets</p></a>
  </VerticalTimelineElement>
  <VerticalTimelineElement
    className="vertical-timeline-element--work"
    date="1901 - 1905"
    iconStyle={{ background: '#b9c5c7', color: '#fff' }}
  >
    <h3 className="vertical-timeline-element-title">Wardenclyffe</h3>
    <p>
    Financially supported by J. Pierpont Morgan, Tesla built the Wardenclyffe laboratory and its famous transmitting tower in Shoreham, Long Island between 1901 and 1905. This huge landmark was 187 feet high, capped by a 68-foot copper dome which housed the magnifying transmitter.  It was planned to be the first broadcast system, transmitting both signals and power without wires to any point on the globe.  The huge magnifying transmitter, discharging high frequency electricity, would turn the earth into a gigantic dynamo which would project its electricity in unlimited amounts anywhere in the world.
    </p>
    <a href="http://teslacollection.com/tesla_articles/1902/electrical_world_engineer/nikola_tesla/a_new_tesla_laboratory_on_long_island" target="_blank" rel="noreferrer"><img src="https://teslacollection.s3.amazonaws.com/thumbnails/Volume%2016/Volume%2016%20Page%20099.jpg" alt="A New Tesla Laboratory On Long Island" className="timelineImage" /><p className="timelineText">Above: A New Tesla Laboratory On Long Island</p></a>
    <a href="http://teslacollection.com/tesla_articles/1911/new_york_american/marcel_roland/nikola_tesla_for_the_first_time_describes_his_new_system_for_supplying_wireless_power_to_run_all_the_earth_s_industries" target="_blank" rel="noreferrer"><img src="https://teslacollection.s3.amazonaws.com/thumbnails/Volume%2019/Volume%2019%20Page%20049.jpg" alt="Nikola Tesla For The First Time Describes His New System For Supplying Wireless Power To Run All The Earth's Industries" className="timelineImage" /><p className="timelineText">Above: Nikola Tesla For The First Time Describes His New System For Supplying Wireless Power To Run All The Earth's Industries</p></a>
  </VerticalTimelineElement>
  <VerticalTimelineElement
    className="vertical-timeline-element--work"
    date="1915"
    iconStyle={{ background: '#b9c5c7', color: '#fff' }}
  >
    <h3 className="vertical-timeline-element-title">Rumoured Nobel Prize never received</h3>
    <p>
    In 1915, a New York Times article announced that Tesla and Edison were to share the Nobel Prize for physics.  Oddly, neither man received the prize, the reason being unclear.  It was rumored that Tesla refused the prize because he would not share with Edison, and because Marconi had already received his.
    </p>
    <a href="http://teslacollection.com/tesla_articles/1915/new_york_times/m_u/tesla_s_discovery_nobel_prize_winner" target="_blank" rel="noreferrer"><img src="https://teslacollection.s3.amazonaws.com/thumbnails/Volume%2021/Volume%2021%20Page%20020.jpg" alt="Tesla's Discovery - Nobel Prize Winner" className="timelineImage" /><p className="timelineText">Above: Tesla's Discovery - Nobel Prize Winner</p></a>
  </VerticalTimelineElement>
  <VerticalTimelineElement
    className="vertical-timeline-element--work"
    date="1917"
    iconStyle={{ background: '#b9c5c7', color: '#fff' }}
  >
    <h3 className="vertical-timeline-element-title">Wadenclyfee Demolished</h3>
    <p>
    The erected, but incomplete tower was demolished in 1917 for wartime security reasons.  The site where the Wardenclyffe tower stood still exists with its 100 feet deep foundation still intact.  Tesla's laboratory designed by Stanford White in 1901 is today still in good condition and is graced with a bicentennial plaque.  
    </p>
    <a href="http://teslacollection.com/tesla_articles/1917/electrical_experimenter/h_winfield_secor/u_s_blows_radio_tower" target="_blank" rel="noreferrer"><img src="https://teslacollection.s3.amazonaws.com/thumbnails/Volume%2021/Volume%2021%20Page%20125.jpg" alt="U.S. Blows Radio Tower" className="timelineImage" /><p className="timelineText">Above: U.S. Blows Radio Tower</p></a>
  </VerticalTimelineElement>
  <VerticalTimelineElement
    className="vertical-timeline-element--work"
    date="1931"
    iconStyle={{ background: '#b9c5c7', color: '#fff' }}
  >
    <h3 className="vertical-timeline-element-title">Time Magazine</h3>
    <img src="/timeline/time_magazine_1931.jpeg" alt="Time Magazine Man of the Year" className="timelineImage" />
    <p>
    On his 75th birthday in 1931, the inventor appeared on the cover of Time Magazine. On this occasion, Tesla received congratulatory letters from more than 70 pioneers in science and engineering including Albert Einstein. These letters were mounted and presented to Tesla in the form of a testimonial volume.  
    </p>
    
  </VerticalTimelineElement>
  <VerticalTimelineElement
    className="vertical-timeline-element--work"
    date="1943"
    iconStyle={{ background: '#b9c5c7', color: '#fff' }}
  >
    <h3 className="vertical-timeline-element-title">Tesla's Death</h3>
    <a href="/timeline/tesla_funeral.gif" target="_blank" rel="noreferrer"><img src="/timeline/tesla_funeral.gif" alt="Tesla's Funeral" className="timelineImage" /></a>
    <p>
    Tesla died on January 7th, 1943 in the Hotel New Yorker, where he had lived for the last ten years of his life.  Room 3327 on the 33rd floor is the two-room suites  he occupied.
A state funeral was held at  St. John the Divine Cathedral in New York City. Telegrams of condolence were received from many notables, including the first lady Eleanor Roosevelt and Vice President Wallace. Over 2000 people attended, including several Nobel Laureates. He was cremated in Ardsley on the Hudson, New York. His ashes were interned in a golden sphere, Tesla’s favorite shape, on permanent display at the Tesla Museum in Belgrade along with his death mask.
    </p>
  </VerticalTimelineElement>
</VerticalTimeline>
	  </div>
  );
};

export default Timeline;