import React from 'react';

import './NavFooter.css';

const NavFooter = () => {
  return (
    <footer className="pt-4 my-md-5 pt-md-5 border-top">
      <div className="row">
        <div className="col-12 col-md text-center">
          <small className="d-block mb-3 text-muted">&copy; 2024 - "The Tesla Collection".  <br />When citing from this web site the following citation format should be used:<br/>
Rudinska, Iwona. Editor "The Tesla Collection." Original Article Author, "Article Title." Publication Publication Date: Page(s). (http://www.teslacollection.com)</small>
        </div>
      </div>
    </footer>
  );
};

export default NavFooter;