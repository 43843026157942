import React from 'react';

import './About.css';

const About = props => {
  return (
    <div className="container">
      <div className="row mt-5">
				<div className="section_text">
          <h1 className="text-center">INTRODUCTION</h1>
	        <div className="text-center introduction_image">
            <img src="/about/collection_books.jpg" id="about_intro" alt="" className="text-center"/>
          </div>
          <p>Prior to its launch on the web The Tesla Collection originated as a set of 23 oversize Volumes published in 1997 and sold as a limited edition to various libraries and individuals around the country.  What follows is the original introduction and forward to the Published version of the collection.</p>
          <p>The genesis of The Tesla Collection began nearly 18 years ago while I was co-producing and editing a documentary-video on Nikola Tesla.  While researching for the video I was appalled to discover how little primary source material was available on the inventor.  There were, of course, an assortment of biographies and several bibliographies in print, but period magazine and newspaper articles were hard to find and, what little I was able to locate, did not provide me with what I felt was an accurate picture of the man within the context of his times.  I wanted more of this sort of material because I thought that it could become an important aspect of the documentary.</p>
          <p>There was an interesting selection of periodical and newspaper material halfway around the world at the Nikola Tesla Museum in Belgrade.  But much of the museum's older paper items were both extremely fragile - in some cases, so brittle that it would fall apart in ones hands - and still un-cataloged, except as lists of titles and dates.  It was not really available to all but the most trusted and ardent researchers.  In order to do a complete search, hundreds and hundreds of storage boxes would have to be examined.  Because time was short, and a completion deadline loomed before me I was forced - for the video-documentary at least - to rely on the available secondary source biographical material.  Therefore, I concentrated on only the biographical aspects of Tesla's life without presenting the man as his contemporaries saw him.</p>
          <p>As most anyone who has ever studied Nikola Tesla must reluctantly admit, as soon as I began delving into the inventor's life I soon found myself smitten by the Tesla 'bug'.  So, after completing the documentary - and purely to satisfy my own curiosity - I began researching Tesla on my own.  Familiar material didn't much interest me.  Letters and personal records, although of great importance, had been mined by Tesla's biographers and had formed the core of their books.  What I was searching for neither these books, nor the archival records from which these books were sourced, could teach me.</p>
          <p>I soon came to understand that if I wanted to learn how Tesla's world had seen him, then I would have to go through everything that had been written about the man when his celebrity was at its height.  Indeed, that is exactly what I did do.  From the articles that appeared on Tesla almost weekly in the technical press and through the scores and scores of full page Sunday Supplement articles which filled the "tabloid" newspapers of the period,  I read it all.  And, as I began to dig up this material and read it, I began to discover things about Tesla and his work that the biographies either hadn't gotten to, or hadn't gotten right.  It didn't take me long to realize why.  It had simply been too difficult and time consuming for any writer to plow through this wealth of material in anything but a cursory manner.  To get to it all - besides the not inconsiderable expense of photocopying everything - it would have taken months if not years of dedicated work.  Because of time constraints, and/or the unavailability of the material, what many writers had done was to select samples and using these samples speculate what the whole might have been.  In fact, in the Tesla video documentary, that's exactly what we had done.  Not only did I find this discovery something of a revelation, but it also forced me to rethink what I had taken as truth from the biographies I had read or seen about other notable persons.  If this had been true of Tesla, then it could have been true of almost any celebrity.</p>
          <p>Today, as Tesla continues to be rediscovered and his scientific renown is once again entering the cultural mainstream, the reader has relied on Tesla's biographies to learn about his once great fame and how his celebrity was the equal of Edison's, and even greater than Marconi's.  Because these readers, and the writers whom they've read, have never extensively studied the media coverage that had - at the height of his fame - been accorded Tesla, the level of that fame had to be taken on faith.  In fact, many things about Tesla must be taken on faith and, therefore, much misinformation about the inventor and his achievements has found its way into the public consciousness.</p>
          <p>It didn't take me long to realize that if there was going to be a true and honest rediscovery of Tesla and his achievements, then the material that I was uncovering had to be put together in a form that would make it readily available not only to professional writers and academics but also to anyone who has the Tesla "bug."  With this goal in mind, I started work on what would eventually become the 3500 page  Tesla Collection.</p>
            
          <p>Almost as soon as I began work on The Collection I established certain parameters.  The newspaper and periodical material would cover the years 1896 through 1920.  Not only were these the years during which Tesla completed his major work, but this was the period that contained the most difficult material to locate.  Also, since Tesla worked in the United States and published in English, The Collection would contain only English language articles.</p>
          <p>Newspapers posed a bit of a problem.  During this period, few newspapers outside New York City were indexed and, therefore, it would be impossible to collect comprehensively from these regional sources.  But, at the turn of the century, nearly half of the 11 daily newspapers published in New York City were indexed.  Consequently the decision was made to concentrate, in-depth, on the New York newspapers.  Not only did Tesla live and work in New York, but this was the era of Pulitzer and Hearst when the mass media, as we know it today, had begun to flourish.  In addition to newspapers, almost every major national magazine either published or had offices in the City.  Since New York City was the center of this media explosion, and a principle source for the burgeoning news services, newspaper coverage of a media icon such as Tesla usually found its way around the country.  Therefore, by concentrating on the New York papers, The Collection would present the best possible newspaper coverage on Nikola Tesla without unnecessary duplication.</p>
          <p>When we gathered together and began using existing Tesla bibliographies, we were dismayed to discover the number of inaccuracies.  Lists of articles that were supposed to be in an American electrical journal were actually published in Britain.  Newspaper articles that were listed as having appeared on certain dates and in certain newspapers were not to be found on their microfilmed copies.  Consequently, for the title of an article, page number or even who published what piece - these bibliographies could not always be relied upon.</p>
          <p>There were reasons for these inaccuracies.  First and foremost, most of the listings for these bibliographies were made from clippings.  An array of material on Tesla had been collected by Tesla himself and it 
          is this material that eventually found its way into the Nikola Tesla Museum in Belgrade.  Most daily newspapers publish several editions and occasionally an article appeared in only one of these editions.  Because few newspapers on microfilm contain every edition published, many clippings may not be found on microfilm.  For the Tesla material this was especially true of the New York Sun.  Of the nearly 70 listings we had for this newspaper, 25 could not be found on microfilm.  To remedy this situation - for the New York newspapers at least - on the days listed for a missing citation, we searched through every New York paper published that day in hopes that another newspaper might have covered the same story.  This procedure involved looking through over 500 separate reels of microfilm.</p>
          <p>A second reason for the many inaccuracies in the Belgrade collection was due to a translation problem.  Citations translated into Serbian and then translated back into English were filled with errors.  Yugoslavs in Belgrade working on the Tesla material could not be expected to be familiar with turn-of-the-century American media.  Two typical examples of this sort of error can be found in the listings for the publications "My World" and "Columbia College."</p>
          <p>In looking for "My World" we searched through every listing and every guide available for American periodicals and could not find a thing under that title.  We did eventually find the article, only it wasn't published in "My World."  It had been published as a Sunday Supplement article in the Magazine section of The Sunday New York World.  Somewhere in the translation cycle "N.Y." had metamorphosed into "My."</p>
          <p>The listing for "Columbia College" had an even more interesting evolution.  The article in that periodical had been written by Michael Pupin - a leading  Columbia University faculty member.  Having trouble locating a listing for this publication we decided to go to the Columbiana Library at Columbia University.  The Columbiana Library specializes in all things related to the University.  For that period they did have something called The Columbia Magazine, published yearly and only circulated to Columbia staff and faculty.  There was nothing in it by Pupin.  Columbiana has a Michael Pupin clipping file.  The file contains a list, written in Pupin's own hand, of all his published pieces.  The "Columbia College" article was not on the list.  But, while looking through the Pupin articles collected in the file, it suddenly became apparent what the mistake had been.  As a Columbia University faculty member, Pupin always signed his pieces as "Michael Pupin, Columbia College."  Someone unfamiliar with this procedure had accidentally turned "Columbia College" into the magazine "Columbia College."  Subsequently we did find the article, but this time in a magazine that had actually been published.</p>
          <p>A third reason for many of the incorrect citations in all Tesla bibliographies is very simple.  Inaccuracies in early bibliographies were copied into the later listings without first checking through the original publications.  The same mistakes found in one 1953 listing are also contained in a bibliography published in 1979.</p>
          <p>For us to complete The Collection, all of these incorrect citations had to be corrected.  Whenever using a self-indexed publication - usually indexed semiannually - we went through every indexed volume, not only searching under "Tesla" but also under related topics.  For a single electrical journal this meant searching through indexes contained in over 80 of its bound volumes.  In spite of this effort, and because several publications in which articles appeared either no longer exist or could not be located, approximately 80 citations listed in these bibliographies could not be found and therefore are not represented in The Collection.</p>
          <p>To insure quality reproduction, we tracked down and duplicated our material from, if they still existed, original paper copies.  Only when paper copies were no longer available did we, as a final resort, use microfilm.  Unfortunately, this could not be done with newspapers.  Since the original papers, some of them over a hundred years old, have long since turned to dust, here we were forced to depend on microfilm.  Most of the New York City newspapers of the period were photographed by the New York Public Library during the 1950s and early 60s - just in time, as it turned out.  The yellowed newspapers had already begun disintegrating.  During this period microfilm technology was still being refined, and since the work by the New York Public Library was done under time and budget constraints, copies made from this source were usually substandard.  Trying to work our way around this problem we checked extant newspaper morgues for New York City newspapers.  We found that over the years these files had been culled and pruned so that no pre-1930 Tesla items were available from that source.  Therefore we were often forced to use some substandard microfilm.  Reproductions made from this material, while not always the best, is still readable.</p>
          <p>The one New York newspaper that could have proved an exception to this rule was the New York Times.  Microfilmed privately, it was subsequently re-photographed and reproductions made from these later reels are of the highest quality.  Because The Times is the only extant New York newspaper with a published index, its reels suffer from heavy use.  We went to seven libraries looking for reels which weren't filled with lines, scratches and other damage without success.  Because of this problem we even contemplated purchasing the reels that we needed.  But, because of the manner in which these reels are sold, purchase would have more than doubled the research cost of this project.</p>
          
          <p>A few final points about The Collection and its indexing:</p>
          <ul>
            <li>All articles that appeared as a series are grouped and listed together even though they may have appeared months apart.</li>
            <li>Titles in the Date Index are exactly as they appear on the original articles.</li>
            <li>All articles are reproduced at 100 percent their original size.</li>
            <li>Publications sometimes change names.  If the name change was minor it is listed in the Publication Index under the key name.  If the name change is more pronounced, its various titles are listed one under the other.</li>
          </ul>	
            
          <p>From inception to completion The Tesla Collection took almost four years to compile.  During this time there were many people and research facilities which helped make this project possible.  Therefore, I would like to express my thanks to the following institutions and their staffs:  the libraries of the University of Colorado, Columbia University, Cornell University, Rutgers University, The University of Minnesota, New York University, The University of Pennsylvania and Yale University as well as the following research libraries:  The Center for Research Libraries, The Library of Congress, the New York Public Library, Pikes Peak Library District of Colorado Springs and the New York State Library at Albany.</p>
          <p>In addition, I would like to give special thanks to a number of individuals.  William Hovemeyer and John J. Zimmerman of the Columbia University Department of Engineering for their encouragement and providing us with access to Tesla material and especially to John Zimmerman, who went far out of his way to make sure we had everything that we needed.  Jim Sorenson for his help.  Mary Davis of the Pikes Peak Library District for searching through the library's Tesla files and microfilmed newspapers.  Martin Bourke of the New York Public Library Map Division for making the only extant copy of the New York Herald Index available to us as well as the New York Sun clipping file of.  And finally, thanks to Michelle Cadoree of the Science and Technology Division of The Library Of Congress for her assistance in making that institution's material available to us.  And, finally, a special thanks must go to Tiberiu Stanescu.  Without his help this project would never have been completed.</p>
          
          <p>
          Iwona Rudinska<br /> 
          Executive Director<br />
          The Tesla Collection<br />
          </p>
          
          <h1 className="text-center">FORWARD</h1>
          <p>The Tesla Memorial Society is pleased to co-sponsor the publication of The Tesla Collection.  This is the most comprehensive compilation of newspaper and periodical articles chronicling the achievements and celebrity of my great uncle Nikola Tesla.  The collection spans the period from Tesla's first public recognition through the most remarkable and creative part of his extraordinarily productive life.</p>
          <p>The purpose of the Society is "to honor and perpetuate the memory and ideals of the great scientist and inventor, Nikola Tesla."  The Tesla Collection meets these aims and goals in the most valuable manner by assembling in one place the public record of his life and work in a way previously unavailable to even the most industrious biographer.</p>
          <p>Nikola Tesla lived in a special era where, because his discoveries had an almost immediate impact on everyday life, he became a "superstar" within the popular culture.  How dramatically Tesla influenced life, then and today, can be demonstrated with just two examples among his hundreds of inventions.  The first, the harnessing of Niagara Falls in 1896 marked the advent of the modern electrical age.  The entire system of Alternating Current power generation, transmission and utilization still in use today was the creation of Nikola Tesla.  The second example, his primacy in radio has been confirmed through inventions that made modern radio transmission practical.  These two discoveries forever changed the face of modern industry and society.</p>
          <p>I was always aware of Nikola Tesla through the recollections by my father, Nikola Trbojevich, Tesla's nephew, 30 years his junior, a famous inventor in his own right and the only family member to join Tesla in America.  But, during these conversations, Tesla's fame was seldom mentioned.  Greater emphasis was given to the technical and family matters they shared.  When, as I grew older and became aware of it, the magnitude of Tesla's coverage by the popular press came as a revelation.</p>
          <p>The light that shined so brightly in Tesla's lifetime has been captured by The Tesla Collection.  Its publication is another giant step in the current resurgence of interest in this gifted man,</p>
          
          <p>
          William H. Terbo<br />
          Honorary Chairman<br />
          Chairman of the Executive Board<br />
          Tesla Memorial Society Inc.<br />
          </p>
        </div>
		  </div>
    </div>
  );
};

export default About;