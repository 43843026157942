import React from 'react';

import Select from 'react-select';
import makeAnimated from 'react-select/animated';

const animatedComponents = makeAnimated();

export default function SubjectrSelectList(props) {
  const options = [
      {"value":47,"label":"AIRPLANES","url":"airplanes"},
      {"value":6,"label":"ALTERNATING CURRENT/MOTORS/TURBINES","url":"alternating_current_motors_turbines"},
      {"value":22,"label":"AWARDS/HONORS","url":"awards_honors"},
      {"value":13,"label":"BIOGRAPHICAL","url":"biographical"},
      {"value":62,"label":"BLADELESS MOTOR/TURBINE","url":"bladeless_motor_turbine"},
      {"value":60,"label":"BOATS","url":"boats"},
      {"value":34,"label":"BOOK REVIEWS","url":"book_reviews"},
      {"value":2,"label":"BUSINESS","url":"business"},
      {"value":64,"label":"CLOCK","url":"clock"},
      {"value":53,"label":"COLORADO","url":"colorado"},
      {"value":46,"label":"CONDENSER","url":"condenser"},
      {"value":35,"label":"CONDUCTORS","url":"conductors"},
      {"value":56,"label":"COURT","url":"court"},
      {"value":18,"label":"CRITICISM OF","url":"criticism_of"},
      {"value":25,"label":"DREHSTROM","url":"drehstrom"},
      {"value":10,"label":"EDISON","url":"edison"},
      {"value":5,"label":"ELECTRICITY","url":"electricity"},
      {"value":23,"label":"EXPERIMENTS","url":"experiments"},
      {"value":30,"label":"HERTZ","url":"hertz"},
      {"value":14,"label":"HIGH FREQUENCY","url":"high_frequency"},
      {"value":4,"label":"INVENTIONS","url":"inventions"},
      {"value":38,"label":"LABORATORY FIRE","url":"laboratory_fire"},
      {"value":7,"label":"LECTURES","url":"lectures"},
      {"value":3,"label":"LIGHT","url":"light"},
      {"value":59,"label":"LIGHTENING","url":"lightening"},
      {"value":54,"label":"MARCONI","url":"marconi"},
      {"value":44,"label":"MARS","url":"mars"},
      {"value":42,"label":"MEDICINE","url":"medicine"},
      {"value":50,"label":"MENTIONS","url":"mentions"},
      {"value":17,"label":"METERS","url":"meters"},
      {"value":32,"label":"MIND READING","url":"mind_reading"},
      {"value":12,"label":"MINING","url":"mining"},
      {"value":66,"label":"MISC.","url":"misc"},
      {"value":65,"label":"MOON","url":"moon"},
      {"value":69,"label":"NewRecordCreated","url":"new_record"},
      {"value":70,"label":"NewRecordCreated","url":"new_record"},
      {"value":67,"label":"NewRecordCreated","url":"new_record"},
      {"value":68,"label":"NewRecordCreated","url":"new_record"},
      {"value":31,"label":"NIAGARA","url":"niagara"},
      {"value":33,"label":"OSCILLATOR","url":"oscillator"},
      {"value":19,"label":"PATENTS","url":"patents"},
      {"value":28,"label":"PERSONAL","url":"personal"},
      {"value":48,"label":"PHOTOGRAPHS","url":"photographs"},
      {"value":39,"label":"POETRY","url":"poetry"},
      {"value":36,"label":"POLOTICS","url":"polotics"},
      {"value":27,"label":"PRAISE","url":"praise"},
      {"value":24,"label":"PREDICTIONS & THEORIES","url":"predictions_theories"},
      {"value":21,"label":"PUPIN","url":"pupin"},
      {"value":41,"label":"RAILROAD","url":"railroad"},
      {"value":63,"label":"RELIGION","url":"religion"},
      {"value":52,"label":"REMOTE CONTROL","url":"remote_control"},
      {"value":40,"label":"ROYALTY","url":"royalty"},
      {"value":55,"label":"SHIPS","url":"ships"},
      {"value":61,"label":"SLEEP","url":"sleep"},
      {"value":37,"label":"STEAM ENGINE","url":"steam_engine"},
      {"value":45,"label":"SUBWAY","url":"subway"},
      {"value":49,"label":"SUN","url":"sun"},
      {"value":29,"label":"TESLA COIL","url":"tesla_coil"},
      {"value":8,"label":"TESLA/FERRARIS","url":"tesla_ferraris"},
      {"value":15,"label":"TESLA/THOMPSON","url":"tesla_thompson"},
      {"value":11,"label":"TRANSFORMERS","url":"transformers"},
      {"value":51,"label":"WAR","url":"war"},
      {"value":58,"label":"WARDENCLYFFE","url":"wardenclyffe"},
      {"value":57,"label":"WATER","url":"water"},
      {"value":26,"label":"WEAPONS","url":"weapons"},
      {"value":9,"label":"WEATHER","url":"weather"},
      {"value":43,"label":"WIRELESS","url":"wireless"},
      {"value":16,"label":"WIRELESS ELECTRICITY","url":"wireless_electricity"},
      {"value":20,"label":"WORLD'S FAIR/CONVENTIONS","url":"world_s_fair_conventions"},
      {"value":1,"label":"X-RAY","url":"x_ray"}
    ];

  function checkOptionValue(option) {
    if(props.selectedIds != null) {
      return props.selectedIds.includes(option.value.toString());
    } else {
      return false;
    }
  }

  function updateSelectedIds(values) {
    var selectedIds = [];
    if(values !== null) {
      selectedIds = values.map(value => value.value);
    }

    console.log("selectIds: " + selectedIds);
    props.updateIds(selectedIds)
  }

  function getFacetValues() {
    var facetIds = (props.facets !== null && props.facets !== undefined) ? props.facets : [];

    if(facetIds.length > 0) {
      var result = options.filter(option => {
        return facetIds.includes(option.value)
      });
  
      return result;
    } else {
      return undefined;
    }
  }

  return (
    <Select
      name="subjectIds[]"
      closeMenuOnSelect={false}
      components={animatedComponents}
      isMulti
      isDisabled={props.isDisabled}
      defaultValue={options.filter(checkOptionValue)}
      value={getFacetValues()}
      onChange={updateSelectedIds}
      options={options}
    />
  );
}