import React from 'react';
import { Link } from 'react-router-dom';
import {Nav}  from 'react-bootstrap';

import './NavBar.css';

const NavBar = () => {
  return (
    <div>
      <div className="d-flex flex-column flex-md-row align-items-center p-3 px-md-4 mb-3 bg-white border-bottom box-shadow">
        <h5 className="my-0 mr-md-auto font-weight-normal"><Link to="/"><img src="/the_tesla_collection.jpg" className="teslaLogo" alt="The Tesla Collection" /></Link></h5>
        <Nav className="my-2 my-md-0 mr-md-3">
          <Link className="p-2 text-dark" to="/">Articles</Link>
          <Link className="p-2 text-dark" to="/timeline">Timeline</Link>
          <Link className="p-2 text-dark" to="/directors">Directors</Link>
          <Link className="p-2 text-dark" to="/about">About</Link>
          <Link className="p-2 text-dark" to="/contact">Contact</Link>
        </Nav>
        <div className="form-group has-search">
          <span className="fa fa-search form-control-feedback"></span>
          <form action="/articlesearch">
            <input type="text" className="form-control" placeholder="Search" name="q" />
          </form>
        </div>
      </div>
    </div>
  );
};

export default NavBar;