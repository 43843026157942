import React from 'react';
import axios from 'axios';
import SearchResults from '../SearchResults/SearchResults';
import qs from 'qs'

class SearchResultsContainer extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			articles: {},
			facets: {},
			query: qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).q,
			publicationIds: qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).publicationIds,
			authorIds: qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).authorIds,
			yearIds: qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).yearIds,
			subjectIds: qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).subjectIds,
			loading: true,
			isDisabled: false,
            message: ''
		};
		this.updateIds = this.updateIds.bind(this);
		this.updatePublicationIds = this.updatePublicationIds.bind(this);
		this.updateAuthorIds = this.updateAuthorIds.bind(this);
		this.updateYearIds = this.updateYearIds.bind(this);
		this.updateSubjectIds = this.updateSubjectIds.bind(this);
	}

	updateIds(key, ids) {
		//console.log(`Updating ${key}!: ` + ids);
		this.setState({ [`${key}`]: ids}, () => {
			this.fetchSearchResults(1);
		});
	}

	updatePublicationIds(ids) {
		this.updateIds('publicationIds', ids)
	}

	updateAuthorIds(ids) {
		this.updateIds('authorIds', ids)
	}

	updateYearIds(ids) {
		this.updateIds('yearIds', ids)
	}

	updateSubjectIds(ids) {
		this.updateIds('subjectIds', ids)
	}

	componentDidMount() {
		this.fetchSearchResults(1);
	}

	parseFacets(res) {
		var publicationIds = [];
		var authorIds = [];
		var yearIds = [];
		var subjectIds = [];

		// TODO: Parse out counts and display?
		if(res.aggregations != null && res.aggregations.asMap) {
			var agg = res.aggregations.asMap;
			if(agg.publicationIds != null) {
				publicationIds = agg.publicationIds.buckets.map(bucket => bucket.key)
			}
			if(agg.authorIds != null) {
				authorIds = agg.authorIds.buckets.map(bucket => bucket.key)
			}
			if(agg.yearIds != null) {
				yearIds = agg.yearIds.buckets.map(bucket => bucket.key)
			}
			if(agg.subjectIds != null) {
				subjectIds = agg.subjectIds.buckets.map(bucket => bucket.key)
			}
		}

		var parsed = {
			publicationIds: publicationIds,
			authorIds: authorIds,
			yearIds: yearIds,
			subjectIds: subjectIds
		}
		// console.log(parsed);
		return parsed;
	}

	fetchSearchResults(updatedPageNo = '') {
		//console.log(this.state);
		const pageNumber = updatedPageNo ? `&page=${updatedPageNo}` : '';
		// TODO: Paginate the results is 20
		var searchUrl = ""
		if(this.state.query !== undefined) {
			searchUrl = `/api/v1/search?&q=${this.state.query}${pageNumber}`;
		} else {
			searchUrl = `/api/v1/search/ids?${pageNumber}&`;
			if(this.state.publicationIds !== undefined) {
				searchUrl += `publicationIds=${this.state.publicationIds}&`
			}
			if(this.state.authorIds !== undefined) {
				searchUrl += `authorIds=${this.state.authorIds}&`
			}
			if(this.state.yearIds !== undefined) {
				searchUrl += `yearIds=${this.state.yearIds}&`
			}
			if(this.state.subjectIds !== undefined) {
				searchUrl += `subjectIds=${this.state.subjectIds}&`
			}
		}
		
		if (this.cancel) {
			// Cancel the previous request before making a new request
			this.cancel.cancel();
		}
		// Create a new CancelToken
		this.cancel = axios.CancelToken.source();
		axios
			.get(searchUrl, {
				cancelToken: this.cancel.token,
			})
			.then((res) => {
				const resultNotFoundMsg = !res.data.searchHits.length
					? 'There are no more search results. Please try a new search.'
					: '';
				this.setState({
					articles: res.data.searchHits,
					facets: this.parseFacets(res.data),
					message: resultNotFoundMsg,
					loading: false,
					isDisabled: (this.state.query !== undefined) ? true: false // Disable the selection boxes for query facets
				});
			})
			.catch((error) => {
				if (axios.isCancel(error) || error) {
					this.setState({
						loading: false,
						message: 'Failed to fetch results.  Please check network',
					});
				}
			});
	}
	render() {
	  console.log(this.state);
	  return (
		  <SearchResults 
			  articles={this.state.articles} 
			  facets={this.state.facets}
			  query={this.state.query}
			  loading={this.state.loading} 
			  isDisabled={this.state.isDisabled} 
			  publicationIds={this.state.publicationIds}
			  updatePublicationIds={this.updatePublicationIds}
			  authorIds={this.state.authorIds}
			  updateAuthorIds={this.updateAuthorIds}
			  yearIds={this.state.yearIds}
			  updateYearIds={this.updateYearIds}
			  subjectIds={this.state.subjectIds}
			  updateSubjectIds={this.updateSubjectIds}
		 />
		);
	}
};

export default SearchResultsContainer;