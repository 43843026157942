import React from 'react';

const Contact = props => {
  return (
    <div className="container">
      <div className="row mt-5">
				<div className="section_text">
          <h1 className="text-center">CONTACT</h1>
          <p>If you have any questions about the site, have encountered problems, noticed incorrect listings or any other problems please let us know.</p>
          <p>Any questions, comments or suggestions should be emailed to: <a href="mailto:contact@teslacollection.com">contact@teslacollection.com</a>.</p>
        </div>
      </div>
    </div>
  );
};

export default Contact;