import React from 'react';

import './Directors.css';

const Directors = props => {
  return (
    <div className="container">
      <div className="row mt-5">
        <div className="col-sm-12">
	        <h1 className="text-center">IWONA RUDINSKA<br /> EXECUTIVE DIRECTOR</h1>
          <div id="iwona_director_image" className="text-center">
            <img src="/directors/iwona.jpg" alt="Iwona RUDINSKA" /><br />
            <div id="iwona_director_image_description">Above: Iwona Rudinska.  Photo: Peter Vujovic.</div>
          </div>
	        <p className="director_description">Iwona Rudinska speaks five languages and, trained as a nurse in Poland, immigrated to America as a 20 year old.  By age 35 she was an American citizen, had five children and directed and edited several documentaries including a video on the life and work of Nikola Tesla.  This Tesla documentary sparked her interest in the inventor and, after four years of research and a great deal of work, finally produced The Tesla Collection.  The eclectic Ms. Rodinska also paints and some of her work can be found on her website: <a href="http://rudinska.com/index.html" target="_blank" rel="noreferrer" alt="rudinska.com">rudinska.com</a>.</p>
        </div>
      </div>

      <div className="row mt-5">
        <div className="col-sm-12">
	        <h1 className="text-center">MARKO VUJOVIC<br /> TECHNICAL DIRECTOR</h1>
	        <div id="marko_director_image" className="text-center">
            <img src="/directors/markovujovic.jpg" className="director_image" alt="Marko Vujovic" />
            <div id="iwona_director_image_description">Above: Marko Vujovic</div>
          </div>
	        <p className="director_description">Marko Vujovic has been fascinated with computers and the internet since an early age.  He received a Bachelor of Science in Computer Science from SUNY New Paltz.  He has worked in a wide range of fields - Ecommerce, Data Reporting, Inventory Management and many others.  Marko was responsible for converting the "Tesla Collection" from a print media into a digital platform where users can search by publication, author, subject and date.</p>
        </div>
      </div>
	</div>
  );
};

export default Directors;