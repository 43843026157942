import React from 'react';
import ReactHtmlParser from 'react-html-parser'; 
import PublicationSelectList from '../PublicationSelectList/PublicationSelectList';
import AuthorSelectList from '../AuthorSelectList/AuthorSelectList';
import YearSelectList from '../YearSelectList/YearSelectList';
import SubjectSelectList from '../SubjectSelectList/SubjectSelectList';

import './SearchResults.css';


// Presentational Component
class SearchResults extends React.Component {
    render() {  
      //console.log(this.props);
      var facets = this.props.facets;
      var publicationFacets = (facets.publicationIds !== undefined && facets.publicationIds !== null) ? facets.publicationIds : [];
      var authorFacets = (facets.authorIds !== undefined && facets.authorIds !== null) ? facets.authorIds : [];
      var yearFacets = (facets.yearIds !== undefined && facets.yearIds !== null) ? facets.yearIds : [];
      var subjectFacets = (facets.subjectIds !== undefined && facets.subjectIds !== null) ? facets.subjectIds : [];
      return (
        <div className="row search-results">
          <div className="col-md-3 row-height">
              <div className="search-facet">
                <div className="card box-shadow">
                  <div className="card-header">
                    <h4 className="my-0 font-weight-normal text-center">Publication</h4>
                  </div>
                  <div className="card-body">
                    <div className="form-group">
                      <PublicationSelectList selectedIds={this.props.publicationIds} updateIds={this.props.updatePublicationIds} facets={publicationFacets} isDisabled={this.props.isDisabled} />
                    </div>
                  </div>
                </div>
              </div>
              <div className="search-facet">
                <div className="card box-shadow">
                  <div className="card-header">
                    <h4 className="my-0 font-weight-normal text-center">Author</h4>
                  </div>
                  <div className="card-body">
                    <div className="form-group">
                      <AuthorSelectList selectedIds={this.props.authorIds} updateIds={this.props.updateAuthorIds} facets={authorFacets} isDisabled={this.props.isDisabled}  />
                    </div>
                  </div>
                </div>
              </div>
              <div className="search-facet">
                <div className="card box-shadow">
                  <div className="card-header">
                    <h4 className="my-0 font-weight-normal text-center">Year</h4>
                  </div>
                  <div className="card-body">
                    <div className="form-group">
                      <YearSelectList selectedIds={this.props.yearIds} updateIds={this.props.updateYearIds} facets={yearFacets} isDisabled={this.props.isDisabled} />
                    </div>
                  </div>
                </div>
              </div>
              <div className="search-facet">
                <div className="card box-shadow">
                  <div className="card-header">
                    <h4 className="my-0 font-weight-normal text-center">Subject</h4>
                  </div>
                  <div className="card-body">
                    <div className="form-group">
                      <SubjectSelectList selectedIds={this.props.subjectIds} updateIds={this.props.updateSubjectIds} facets={subjectFacets} isDisabled={this.props.isDisabled} />
                    </div>
                  </div>
                </div>
              </div>
              {/*
              <div className="search-facet">
                <div className="card box-shadow">
                  <div className="card-header">
                    <h4 className="my-0 font-weight-normal text-center">Sort</h4>
                  </div>
                  <div className="card-body">
                    <div className="form-group">
                      <SortSelectList />
                    </div>
                  </div>
                </div>
              </div>
            */}
          </div>

          <div className="col-md-9 row-height">
            {this.props.articles.length > 0 &&
              <div>
                <div className="article-search-count">
                  Article(s) Found: <strong>{this.props.articles.length}</strong>
                </div>
                <table className="table table-striped" id="articles-search-results">
                  <thead className="thead-light text-center">
                      <tr>
                          <th scope="col">Thumbnail</th>
                          <th scope="col">Name</th>
                          <th scope="col">Publication</th>
                          <th scope="col">Author</th>
                          <th scope="col">Date</th>
                          <th scope="col">Subject</th>
                          <th scope="col">Text</th>
                      </tr>
                  </thead>
                  <tbody>
                    {this.props.articles.map(function(a, i) {
                      let article = a.content;
                      let highlights = a.highlightFields;
                      console.log(article);
                      // console.log(highlights);

                      var articleThumbnailImageUrl = "";
                      if(article.thumbnailPage != null && article.thumbnailPage.thumbnailFullPath != null) {
                        articleThumbnailImageUrl = article.thumbnailPage.thumbnailFullPath
                      }

                      var articleTitle = article.title;
                      var articlePublication = article.publication != null ? article.publication.name : 'N/A';
                      var articleAuthor = article.author != null ? article.author.name : 'N/A';
                      var articleDate = article.date;
                      var articleSubjectName = "";
                      if(article.subjects != null && article.subjects.length > 0) {
                        articleSubjectName = article.subjects[0].name;
                      }
                      var articleText = "";

                      if(highlights != null) {
                        if(highlights.title != null) {
                          articleTitle = highlights.title.toString()
                        }
                        if(highlights['publication.name'] != null) {
                          articlePublication = highlights['publication.name'].toString()
                        }
                        if(highlights['author.name'] != null) {
                          articleAuthor = highlights['author.name'].toString()
                        }
                        if(highlights.date != null) {
                          articleDate = highlights.date.toString()
                        }
                        if(highlights['subjects.name'] != null) {
                          articleSubjectName = highlights['subjects.name'].toString()
                        }
                        if(highlights.fullText != null) {
                          articleText = highlights.fullText.toString()
                        }
                      }

                      return (
                          <tr key={i}>
                              <td className="text-center search-result-"><a href={"/tesla_articles/" + article.fullUrl}><img src={articleThumbnailImageUrl} alt={article.title} className="img-thumbnail" /></a></td>
                              <td className="text-center"><a href={"/tesla_articles/" + article.fullUrl}>{ ReactHtmlParser(articleTitle) }</a></td>
                              <td className="text-center">{ ReactHtmlParser(articlePublication) }</td>
                              <td className="text-center">{ ReactHtmlParser(articleAuthor) }</td>
                              <td className="text-center">{ ReactHtmlParser(articleDate) }</td>
                              <td className="text-center">{ ReactHtmlParser(articleSubjectName) }</td>
                              <td className="text-center">
                                <div className="article-text"> { ReactHtmlParser(articleText) } </div>
                              </td>
                          </tr>
                        );
                    })}
                  </tbody>
                </table>
              </div>
            }
          </div>
        </div>
      );
    }
};

export default SearchResults;